<template>
  <div id="modalallprops">
    <mdb-container>
      <mdb-row>
        <mdb-col xl="4" sm="12" md="12" lg="6" v-for="(item , index) in Ejemplo" >
            <mdb-card    style=" margin-top: 25px" >
              <!-- Default checked -->
              <div class="custom-switch">
                <!-- {{item.}} -->
              <input @click="ActivateProp(item)"  type="checkbox" class="custom-control-input" :id="item.id" v-bind:key="item.id" :checked="item.control_active">
                <label class="custom-control-label" :for="item.id"> {{ item.control_active ? 'Activado' : 'Desactivado'}} </label>
              </div>
              <div class="d-flex justify-content-center">
                <mdb-view hover>
                  <a href="#!">
                    <mdb-card-image
                      :src =item.src[0].src
                      alt="Card image cap"
                      class="text-center"
                      />
                    <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
                  </a>
                </mdb-view>
              </div>
              <div>
            </div>

              <mdb-card-body class="text-center">
                <mdb-row >
                  <mdb-col col ="6">
                    <h6 align="left"><b>ID</b>:{{item.id}}</h6>
                  </mdb-col>
                  <mdb-col col ="6">
                    <img @click="Download_prop(item)" align="right" src="@/assets/pdf.png" width="50" height="50">
                  </mdb-col>
                  <mdb-col col ="6">
                    <h6 align="left"><b>Tipo</b>:{{item.type}}</h6>
                  </mdb-col>
                  <mdb-col col ="6">
                    <h6 align="left"><b>Precio</b>:{{item.precio}}</h6>
                  </mdb-col>
                  <mdb-col col ="12">
                    <h6 v-if="item.renta == true && item.venta == false" align="left"><b>Status</b>:En renta</h6>
                    <h6 v-if="item.venta == true && item.renta == false" align="left"><b>Status</b>:En venta</h6>
                    <h6 v-if="item.venta == true && item.renta == true" align="left"><b>Status</b>:En venta y renta</h6>
                  </mdb-col>
                  <mdb-col col ="6">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon">
                        <mdb-icon icon="bath" />
                      </span>
                      <h6 align="left"><b>: </b>{{item.Baño}}</h6>
                    </div>
                  </mdb-col>
                  <mdb-col col ="6">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon">
                        <mdb-icon icon="bed" />
                      </span>
                      <h6 align="left" ><b>: </b>{{item.Habitacion}}</h6>
                    </div>

                  </mdb-col>
                  <mdb-col col ="6">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon">
                        <mdb-icon icon="car" />
                      </span>
                      <h6 align="left" ><b>: </b>{{item.Estacionamiento}}</h6>
                    </div>

                  </mdb-col>

                  <mdb-col col ="6">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon">
                        <img src="@/assets/terreno.png" width="20" height="18">
                      </span>
                      <h6 align="left" ><b>: </b>{{item.Terreno}}</h6>
                    </div>

                  </mdb-col>
                </mdb-row >
                <mdb-row >
                  <mdb-col col ="6"xl="6" sm="6" md="6" lg="6" >
                    <mdb-btn color="red" v-on:click ="DeletePropFirebase(item)"> Eliminar</mdb-btn>
                  </mdb-col>
                  <mdb-col col ="6"xl="6" sm="6" md="6" lg="6" >
                    <mdb-btn color="blue" v-on:click ="ActEditProp(item.id_general)"> Editar </mdb-btn>
                  </mdb-col>
                </mdb-row >
                <mdb-row >
                  <mdb-col col ="12" xl="12" sm="12" md="12" lg="12" >
                    <mdb-btn
                    outline="success"
                    v-on:click ="GenerateLink(item)"
                    v-clipboard:copy="message"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"> Generar Link </mdb-btn>
                  </mdb-col>
                </mdb-row >
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
      </mdb-row>
    </mdb-container>
  </div>
</template>


<script>
import XLSX from 'xlsx';
import jspdf from 'jspdf';
import { firebase, storage, db } from "@/firebase";
import VueHtml2Canvas from 'vue-html2canvas';
import 'jspdf-autotable'
// import html2Canvas from 'html2canvas';
import VueHtml2pdf from 'vue-html2pdf'
// Vue.use(VueHtml2pdf)
import { mapActions,mapState } from 'vuex'
import { mdbPagination, mdbPageItem, mdbPageNav } from 'mdbvue';
  import {
      mdbContainer,
      mdbInput,
      mdbCard,
      mdbCardHeader,
      mdbCardTitle,
      mdbCardBody,
      mdbCardText,
      mdbIcon,
      mdbBtn,
      mdbRow,
      mdbCol,
      mdbCardImage,
      mdbView,
      mdbMask,} from "mdbvue";

export default {
  name: 'modalallprops',
  components: {
    VueHtml2pdf,
      mdbPagination,
      mdbPageItem,
      mdbPageNav,
      mdbContainer,
      mdbInput,
      mdbCard,
      mdbCardHeader,
      mdbCardTitle,
      mdbCardBody,
      mdbCardText,
      mdbIcon,
      mdbBtn,
      mdbRow,
      mdbCol,
      mdbCardImage,
      mdbView,
        mdbMask,
    },
  data() {
    return {
      valuemax:5,
      page:0,
      message: 'Holi',

      valuemaxbtn:5,
      pagebtn:0,
    }
  },
  computed:{
      ...mapState(['Ejemplo','Longitud']),
    },
  methods: {
    onCopy: function (e) {
               alert('Se ha generado Link para compartir')
           },
   onError: function (e) {
       alert('No se pudo copiar el texto al portapapeles')
       console.log(e);
   },
   GenerateLink(item){
     console.log(item)
     this.message = "http://www.novoinmobiliaria.com.mx/propiedad/" + item.id_general
   },
    ...mapActions(['DeletePropFirebase','ChangeValueBoolean']),
    ActivateProp(data){
      console.log("Activate Propiedad")
      data.control_active = !data.control_active
      var Update_data= data
      db.collection("Propiedades").doc(data.id_general).update({
        DataProp: Update_data
      }).then(function() {
          console.log("Document successfully updated!");
      })
    },
    Download_prop(prop){
      console.log(prop)
      var doc = new jspdf('p','pt');
      doc.autoTable({
            columnStyles: { 0: { halign: 'center',fontSize:13,fontStyle:'bold'} }, // Cells in first column centered and green
            body: [['FICHA DE PROPIEDAD']],

        })
      var Fecha = new Date(prop.fecha);
      var months = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
      var fecha =Fecha.getDate() + ' de ' + months[Fecha.getMonth()] + ' del ' + Fecha.getFullYear()
      doc.autoTable({
            columnStyles: {
              0: { fontSize:10,fontStyle:'bold',lineWidth:1,fillColor:'white',cellWidth:140},
              1: { fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:380} }, // Cells in first column centered and green
            body: [
              ['NOMBRE:',prop.name],
              ['ID:',prop.id],
              ['TIPO:',prop.type],
              ['PRECIO:',prop.precio],
              ['PRECIO MANTENIMIENTO:',prop.mantenimiento],
              ['ESCRITURA PUBLICA:',prop.escritura_publica],
              ['DESCRIPCION:',prop.descripcion],
              ['ASESOR:',prop.asesor],
              ['FECHA:',fecha ],
              ['DIRECCION:',prop.direccion],
              ['ESTADO DE CONSERVACION:',prop.estado],
              ['FORMA DE TERRENO:',prop.figura],
              ['RENTA:',prop.renta == true ? 'si':'no'],
              ['VENTA:',prop.venta == true ? 'si':'no'],
            ],

        })
      doc.autoTable({
              columnStyles: {
                0: { fontSize:10,fontStyle:'bold',fillColor:'white',cellWidth:140},
                1: { fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:100},
                2: { halign:'right',fontSize:10,fontStyle:'bold',fillColor:'white',cellWidth:160},
                3: {  fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:120} },
              body: [
                ['METROS CONSTRUCCION:',prop.mConstruccion, 'METROS TERRENO',prop.Terreno==0 ? '':prop.Terreno],
              ],

          })
    if (prop.type == 'Casa' ||  prop.type == 'Departamento'){
      doc.autoTable({
              columnStyles: {
                0: { fontSize:10,fontStyle:'bold',fillColor:'white',cellWidth:140},
                1: { fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:60},
                2: { halign:'right',fontSize:10,fontStyle:'bold',fillColor:'white',cellWidth:110},
                3: {  fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:50},
                4: { halign:'right',fontSize:10,fontStyle:'bold',fillColor:'white',cellWidth:110},
                5: {  fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:50} },
              body: [
                ['CODIGO POSTAL',prop.cp, 'CISTERNA',prop.Cisterna==0 ? '' : prop.Cisterna,'JARDIN',prop.Jardin==0 ? '':prop.Jardin],
                ['HABITACIONES',prop.Habitacion == 0? '':prop.Habitacion, 'BAÑOS',prop.Baño == 0? '':prop.Baño,'1/2 BAÑOS',prop.MedioBaño ==0 ? '':prop.MedioBaño],
                ['AIRE ACONDICIONADO',prop.AireAco>0 ? 'Si': 'No', 'CALENTADOR DE AGUA',prop.CalentadorAgu >0 ? 'Si':'No','CALEFACCION',prop.Calefaccion > 0 ? 'Si':'No'],
                ['SEGURIDAD PRIVADA',prop.SeguridaPriv>0 ?'Si':'N0', 'GARAJE',prop.Garaje ==0 ? '':prop.Garaje,'LUGARES DE ESTACIONAMIENTOS',prop.Estacionamiento > 0 ? '':prop.Estacionamiento],
                ['FRACCIONAMIENTO',prop.Fraccionamiento == true ? 'Si' : 'No', 'CONDOMINIO',prop.Condominio == true ? 'Si' : 'No','PRIVADA',prop.Privada == true ? 'Si' :'No' ],
                ['DRENAJE',prop.Drenaje == true ? 'Si' : 'No', 'CISTERNA',prop.Cisterna == 0 ? '' : prop.Cisterna,'CASA SOLA',prop.CasaSol == true ? 'Si' : 'No'],
                ['ALBERCA',prop.Alberca == 0 ? '': prop.Alberca, 'JACUZZI',prop.Jacuzzi == 0 ? '': prop.Jacuzzi,'ALBERCA COMUN',prop.AlbercaCom == 0 ? '' : prop.AlbercaCom],
                ['NIVELES CONTRIBUIDOS',prop.NivelesCon, 'COCINA INTEGRAL',prop.CocinaInt >0 ? 'Si':'No','MASCOTAS',prop.Mascotas == true ? 'Si' : 'No'],
                ['AMUEBLADO',prop.Amueblado == true ? 'Si' : 'No', 'AREA DE JUEGOS',prop.AreaJue == true ? 'Si' : 'No','ANTIGUEDAD',prop.Antiguedad],
              ],

              })
            }else if (prop.type == 'Bodega'){
              doc.autoTable({
                      columnStyles: {
                        0: { fontSize:10,fontStyle:'bold',fillColor:'white',cellWidth:140},
                        1: { fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:60},
                        2: { halign:'right',fontSize:10,fontStyle:'bold',fillColor:'white',cellWidth:110},
                        3: {  fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:50},
                        4: { halign:'right',fontSize:10,fontStyle:'bold',fillColor:'white',cellWidth:110},
                        5: {  fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:50} },
                      body: [
                        ['CODIGO POSTAL',prop.cp, 'TECHADA',prop.Techada==true ? 'Si' : 'No','BAÑOS',prop.Baño==0 ? '':prop.Baño],
                        ['ESTACIONAMIENTOS',prop.Estacionamiento == 0 ? '' : prop.Estacionamiento],
                      ],

                      })
                    }else if (prop.type == 'Terreno'){
                      doc.autoTable({
                              columnStyles: {
                                0: { fontSize:10,fontStyle:'bold',fillColor:'white',cellWidth:140},
                                1: { fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:60},
                                2: { halign:'right',fontSize:10,fontStyle:'bold',fillColor:'white',cellWidth:110},
                                3: {  fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:50},
                                4: { halign:'right',fontSize:10,fontStyle:'bold',fillColor:'white',cellWidth:110},
                                5: {  fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:50} },
                              body: [
                                ['CODIGO POSTAL',prop.cp, 'BARDEADO',prop.Bardeado==true ? 'Si' : 'No','RUSTICO',prop.Rustico==true ? 'Si':'No'],
                                ['FRACCIONAMIENTO',prop.Fraccionamiento == true ? 'Si' : 'No','SOLO',prop.CasaSol == true ? 'Si' :'No', 'PRIVADA',prop.Privada == true ? 'Si' : 'No'],
                              ],

                              })
                            }else if (prop.type == 'Oficina' || prop.Type == 'LocalComercial'){
                              doc.autoTable({
                                      columnStyles: {
                                        0: { fontSize:10,fontStyle:'bold',fillColor:'white',cellWidth:140},
                                        1: { fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:60},
                                        2: { halign:'right',fontSize:10,fontStyle:'bold',fillColor:'white',cellWidth:110},
                                        3: {  fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:50},
                                        4: { halign:'right',fontSize:10,fontStyle:'bold',fillColor:'white',cellWidth:110},
                                        5: {  fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:50} },
                                      body: [
                                        ['CODIGO POSTAL',prop.cp, 'PlazaComercial',prop.PlazaComercial==true ? 'Si' : 'No','SOBRE CALLE',prop.SobreCalle==true ? 'Si':'No'],
                                        ['COORPORATIVO',prop.Corporativo == true ? 'Si' : 'No','LUGAR ESTACIONAMIENTO',prop.Estacionamiento == 0 ? '' :prop.Estacionamiento, 'BAÑOS',prop.Baño == 0 ? '' : prop.Baño],
                                      ],

                                      })
                                    }

       // let calcHeight = ( heightOfTheHeader + ( heightOfSingleRow * ( numberOfRows % maxNumberOfRowInOnePage ) ) );
       var footermax=750
       var sizeLetter=43
        doc.autoTable({
                startY: footermax-sizeLetter,
                margin: { bottom: 20 },
                columnStyles: {
                  0: { valign:'bottom',fontSize:10,fontStyle:'bold',lineWidth:1,fillColor:'white',cellWidth:140},
                  1: { valign:'bottom',fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:380}},
                body: [
                  ['CONTACTO',''],
                  ['TELEFONO',''],
                ],

                })
      doc.autoTable({
              startY:footermax,
              margin: { bottom: 10 },
              columnStyles: {
                0: {halign: 'center' , valign:'middle',fontSize:10,fontStyle:'bold',lineWidth:1,fillColor:'white',cellWidth:140,minCellHeight:70},
                1: {fontSize:10,fontStyle:'normal',lineWidth:1,fillColor:'white',cellWidth:380},minCellHeight:70},
              body: [
                ['OBSERVACIONES',prop.observacion],
              ],

              })

      doc.save("pdf.pdf");


      // const doc = new jsPDF();
      // doc.text("Hello world!", 10, 10);
      // doc.save("a4.pdf");
      // let data = XLSX.utils.json_to_sheet(this.data);
      // const workbook = XLSX.utils.book_new();
      // const filename = "planilla";
      // XLSX.utils.book_append_sheet(workbook, data, filename);
      // XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    ActEditProp(value){
      console.log(value)
      this.ChangeValueBoolean({var:'ShowEditProp',val:true})
      this.ChangeValueBoolean({var:'IndexEditProp',val:value})
    },
    FPagination(value){
      this.page= value
      console.log(value)
    },
    FPaginationbtn_menos(){
      console.log(this.pagebtn)
      if(this.pagebtn>0){
        this.pagebtn = this.pagebtn-1
      }
    },
    FPaginationbtn_mas(){
      // console.log("diego")
      // console.log(this.pagebtn)
      // console.log(this.Longitud.length)
      // console.log((this.Longitud.length/this.valuemaxbtn))
      if(this.pagebtn < (this.Longitud.length/this.valuemaxbtn)-1){
      this.pagebtn = this.pagebtn+1
    }
  }
  },
}
</script>

<style>
.h5{
  font-size:2px;
}
</style>
