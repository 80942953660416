<template>
  <!-- ******************* Start Table ************************** -->
  <div id="table_props">
    <!-- <h3>Datos Generales</h3> -->
    <!-- {{dataProps_Gen}} -->
    <mdb-row >

      <mdb-col xl="12" sm="12" md="12" lg="3" >
        <h3><b>Control</b></h3>
      </mdb-col>
      <mdb-col col="12" xl="3" sm="12" md="3" lg="3" >
        <mdb-input v-model="lblId"  type="text"  label="Id"/>
      </mdb-col>
      <mdb-col col="6" xl="3" sm="3" md="3" lg="3" >
        <mdb-btn color="red" @click="DeleteProp" :disabled="lblId == ''" >Eliminar</mdb-btn>
      </mdb-col>
      <mdb-col col="6" xl="3" sm="3" md="3" lg="3" >
        <mdb-btn color="blue" @click="modalCarrucel = false" :disabled="lblId == '' " >Editar</mdb-btn>
      </mdb-col>
    </mdb-row >
    <br>

    </mdb-row >
      <mdb-col xl="12" sm="12" md="12" lg="12" >
        <h3><b>Seleccion Tabla</b></h3>
        <br>
      </mdb-col>
    </mdb-row >
    <select v-model="TypeTable" class="browser-default custom-select">
      <option value="1">Datos Generales</option>
        <option value="2">Direcciones</option>
        <option value="3">Casas o Departamentos</option>
        <option value="4">Bodegas</option>
        <option value="5">Terrenos</option>
        <option value="6">Oficinas o Locales Comercial</option>
    </select>
    <div v-if="statusTab"  v-show="statusTab" >
      <mdb-datatable v-show="TypeTable == 1" striped bordered :data="dataProps_Gen"/>
      <mdb-datatable v-show="TypeTable == 2" striped bordered :data="dataProps_dir"/>
      <mdb-datatable v-show="TypeTable == 3" striped bordered :data="dataProps_cadep"/>
      <mdb-datatable v-show="TypeTable == 4" striped bordered :data="dataProps_bod"/>
      <mdb-datatable v-show="TypeTable == 5" striped bordered :data="dataProps_ter"/>
        <mdb-datatable v-show="TypeTable == 6" striped bordered :data="dataProps_ofi"/>
    </div>
  </div>
  <!-- ******************* End Table  ************************** -->
</template>


<script>

import { mapActions,mapState } from 'vuex'
import { mdbDatatable } from 'mdbvue';
import {mdbInput,mdbBtn} from "mdbvue";
import {mdbRow, mdbCol} from 'mdbvue';
export default {
  name: 'table_props',
  components: {
    mdbDatatable,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbInput,
  },
  data() {
    return {
      TypeTable:1,
      lblId:"",

    };
  },
  methods: {
    ...mapActions(['DeletePropFirebase']),
    DeleteProp(){
      var self = this
      var ArrayFilter = self.dataProps.filter(function(ArrayFilter) {
        return ArrayFilter.id === self.lblId;});

      if (ArrayFilter.length > 0){
        for (var i= 0; i < ArrayFilter.length; i++){
          self.DeletePropFirebase(ArrayFilter[i])
        }
      }else{
        alert('El id proporcionado es incorrecto')
      }
    }

  },
  computed:{
     ...mapState(['dataProps','dataProps_Gen','dataProps_dir','statusTab','dataProps_cadep','dataProps_bod','dataProps_ter','dataProps_ofi'])
  },
  mounted(){

  },

}
</script>


<style>
</style>
