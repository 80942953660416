<template>
  <div>

    <Whatsapp :id_general="null"></Whatsapp>
    <NavBarGeneral></NavBarGeneral>
    <AboutUs></AboutUs>
  </div>
</template>


<script>

  import AboutUs from "@/components/AboutUs.vue"
  import Whatsapp from "@/components/Whatsapp.vue"
  import NavBarGeneral from "@/components/NavBarGeneral.vue"

  export default {
    name: 'Properties',
    components: {
      
      NavBarGeneral,
      AboutUs,
      Whatsapp,
    },
    data() {
      return {
      };
    },
  }
</script>
