<template>
  <div id="login">
    <mdb-container  style="padding-top: 10%; padding-bottom: 10px;" class="title">
      <mdb-card>
        <div class="d-flex justify-content-center">
          <mdb-view hover>
            <a>
              <img  style="padding-top: 30px" class="img1 center" src="../assets/NovoInmobiliaria_logo.jpg" height="280"	alt="Card image cap">
              <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
            </a>
          </mdb-view>
      </div>
        <mdb-card-body >
          <mdb-card-text>
            <form @submit.prevent="login_user({email:email, password:pass})">
              <div>
                <mdb-input  v-model = "email" type="email"  placeholder="Enter email"/>
              </div>
              <div>
                <mdb-input v-model="pass" type="password"  placeholder="Password"/>
              </div>
              <div class="d-flex justify-content-center">
                <mdb-btn type="submit" class="btn btn-primary"><h6>Ingresar</h6></mdb-btn>
              </div>
            </form>
          </mdb-card-text>
        </mdb-card-body>
      </mdb-card>
    </mdb-container>
  </form>
  <p>{{error}}</p>

  </div>
</template>

<script>
import { mdbContainer, mdbInput, mdbCard, mdbCardHeader, mdbCardTitle,
   mdbCardText, mdbCardBody, mdbIcon, mdbBtn, mdbRow, mdbCol, mdbCardImage, mdbView, mdbMask} from "mdbvue";
import {mapActions,mapState} from 'vuex'
export default {
  name:"Login",
  data(){
    return{
      email:'',
      pass:'',
    }

  },
  components: {
      mdbContainer,
      mdbInput,
      mdbCard,
      mdbCardHeader,
      mdbCardTitle,
      mdbCardBody,
      mdbCardText,
      mdbIcon,
      mdbBtn,
      mdbRow,
      mdbCol,
      mdbCardImage,
      mdbView,
      mdbMask
    },
  methods:{
    ...mapActions(['login_user'])
  },
  computed:{
    ...mapState(['error'])
  }

}
</script>

<style>
.title {
	font-family: "Times New Roman";
	/* display: flex; */
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	letter-spacing: 1px;
}
</style>
