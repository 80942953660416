<template>
    <div id="editdashboard">
      <h3 class="text-center"   style="font-family: 'Times New Roman'"  ><b>Mi Dashboard</b></h3>
    </div>
</template>


<script>
import {auth, db, firebase, storage} from '@/firebase'
export default {
    name: 'editdashboard',
    components: {
    },
    data() {
      return {
      };
    },

  };
</script>
