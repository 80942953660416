<template>
    <div id="editperfil">
      <h3 class="text-center"   style="font-family: 'Times New Roman'"><strong>Mi Perfil</strong></h3>
      <mdb-container>
        <mdb-row>
          <mdb-col col="5" sm="5" md="5" lg="5" xl="5">
              <h3 style="font-family: 'Times New Roman'"  ><strong>Editar Logo</strong></h3>
            <mdb-card>
              <mdb-card-image :src="LogoUrl" alt="Card image cap"></mdb-card-image>

            </mdb-card>
            <input  placeholder="Seleciona tu imagen" type="file" @change="buscarImagen($event)">
            <mdb-btn  color="green" @click="UpdateLogo">Actualizar</mdb-btn>
          </mdb-col>
          <mdb-col col="5" sm="5" md="5" lg="5" xl="5">
           <h3 style="font-family: 'Times New Roman'"  ><strong>Mis Visitas: {{CounterVisit}}</strong></h3>
          </mdb-col>
          <mdb-col col="5" sm="5" md="5" lg="5" xl="5">
            <br>
            <h3 style="font-family: 'Times New Roman'"  ><strong>Editar Password</strong></h3>
            <form @submit.prevent="forgetPassword">
                <button type="submit" class="btn btn-dark btn-lg btn-block">Reset password</button>
            </form>
          </mdb-col>
        </mdb-row>

      </mdb-container>
    </div>
</template>



<script>

import {auth, db, firebase, storage} from '@/firebase'
import {mdbContainer, mdbRow, mdbCol} from 'mdbvue';
import { mdbCard, mdbCardImage, mdbCardBody, mdbCardTitle, mdbCardText, mdbBtn } from 'mdbvue'
import { mdbInput } from 'mdbvue';
 import { mapActions,mapState } from 'vuex'
export default {
    name: 'editperfil',
    components: {

      mdbInput,
      mdbContainer,
      mdbRow,
      mdbCol,
      mdbCard,
			mdbCardImage,
			mdbCardBody,
			mdbCardTitle,
			mdbCardText,
			mdbBtn
    },
    data() {
      return {
        file:null,
        src:null,
        user: {
          email: ''
        }
      };
    },
    computed:{
      ...mapState(['LogoUrl','CounterVisit','usuario'])

    },
    methods: {
      async UpdateLogo(){
        var self = this
        if (self.file != null){
          const refImagen =storage.ref().child('Logo').child('logo.jpg')
          const res = await refImagen.put(self.file)
          const urlDownload =  await refImagen.getDownloadURL()
          db.collection("General").doc("Logo").set({
                    src: urlDownload,
                    from:'remote',
                })
                .then(function() {
                  self.file= null
                  alert('Plantilla Perfil se ha actualizado!')
                    console.log("Document successfully written!");
                })
                .catch(function(error) {
                    console.error("Error writing document: ", error);
                });
        }
      },
      forgetPassword() {
        firebase
        .auth()
        .sendPasswordResetEmail(this.usuario.email)
        .then(() => {
            alert('Check your registered email to reset the password!')
            this.user = {
              email: ''
            }
        }).catch((error) => {
          alert(error)
        })
      },

      buscarImagen(event){
      var self = this
        this.file=event.target.files[0]
        // const reader = new FileReader();
        // reader.readAsDataURL(this.file);
        // reader.onload=(e)=> {
        //   self.src = e.target.result
        //   console.log(self.src)
        // }

      },

      // Inicio Ciclo de vida
      // beforeCreate () {
      //   console.log('1 - beforeCreate from EDITPERFIL')
      // },
      // created () {
      //   console.log('2 - created from EDITPERFIL')
      // },
      // beforeMount () {
      //   console.log('3 - beforeMount from EDITPERFIL')
      // },
      // mounted () {
      //   console.log('4 - mounted from EDITPERFIL')
      // },
      // beforeUpdate () {
      //   console.log('5 - beforeUpdate from EDITPERFIL')
      //
      // },
      // Updated () {
      //   console.log('6 - Update from EDITPERFIL')
      // },
    },
  }
</script>

<style>
</style>
