<template>
  <div>
    <Whatsapp :id_general="null"></Whatsapp>
    <NavBarGeneral></NavBarGeneral>
    <FindProperties></FindProperties>
    <SearchProperties></SearchProperties>
  </div>
</template>


<script>
  import NavBarGeneral from "@/components/NavBarGeneral.vue"
  import Whatsapp from "@/components/Whatsapp.vue"
  import FindProperties from "@/components/FindProperties.vue"
  import SearchProperties from  "@/components/SearchProperties.vue"
  import { mapActions,mapState } from 'vuex';
  export default {
    name: 'Properties',
    components: {
      SearchProperties,
      FindProperties,
      NavBarGeneral,
      Whatsapp,
    },
    data() {
      return {
      };
    },
    methods: {
      ...mapActions(['readLocalidades']),
    },
    mounted () {
      // console.log('4 - mounted frome Home.vue')
      this.readLocalidades()
    },
    // beforeCreate () {
    //   console.log('1 - beforeCreate frome Properties.vue')
    // },
    //
    // beforeMount () {
    //   console.log('3 - beforeMount frome Properties.vue')
    // },
    // mounted () {
    //   console.log('4 - mounted frome Properties.vue')
    //
    // },
    // beforeUpdate () {
    //   console.log('5 - beforeUpdate frome Properties.vue')
    // },
    // Updated () {
    //   console.log('6 - Update')
    // },
  }
</script>
