<template>
    <div id="editcontactprops">
      <h3 class="text-center"   style="font-family: 'Times New Roman'"  ><b>MIS DATOS DE CONTACTO</b></h3>
      <mdb-card>
          <mdb-card-body>
            <mdb-input type="number" label="Numero telefonico" v-model="ContactData.tel" />
            <mdb-input type='email' label="Correo de contacto" v-model="ContactData.email" />
            <mdb-input label="Link Facebook" v-model="ContactData.facebook" />
            <mdb-input label="Link Whatsapp" v-model="ContactData.whatsapp" />
            <mdb-input label="Link Twiter" v-model="ContactData.twiter" />
            <mdb-input label="Link Direccion" v-model="ContactData.direccion" />
            <mdb-input type="number" label="Mapa Latitud" v-model="ContactData.latitud" />
            <mdb-input type="number" label="Mapa Longitud" v-model="ContactData.longitud" />
            <mdb-btn color="green" @click="SaveContactData(DataContact)">Guardar</mdb-btn>
          </mdb-card-body>
      </mdb-card>
    </div>
</template>



<script>
import {auth, db, firebase, storage} from '@/firebase'
 import { mdbInput } from 'mdbvue';
 import { mdbCard, mdbCardImage, mdbCardBody, mdbCardTitle, mdbCardText, mdbBtn } from 'mdbvue';

 import { mapActions,mapState } from 'vuex'
  export default {
    name: 'editcontactprops',
    components: {
      mdbInput,
      mdbCard,
			mdbCardImage,
			mdbCardBody,
			mdbCardTitle,
			mdbCardText,
			mdbBtn
    },
    data() {
      return {
        // DataContact:{
        //   tel:null,
        //   email:'',
        //   facebook:'',
        //   whatsapp:'',
        //   twiter:'',
        //   direccion:'',
        //   latitud:null,
        //   longitud:null
        // }
      };
    },
    computed:{
      ...mapState(['ContactData'])
    },
    methods: {
      SaveContactData(){
        
      db.collection("General").doc("Contacto").set({
                tel: this.ContactData.tel,
                email: this.ContactData.email,
                facebook: this.ContactData.facebook,
                whatsapp: this.ContactData.whatsapp,
                twiter:this.ContactData.twiter,
                direccion:this.ContactData.direccion,
                latitud:this.ContactData.latitud,
                longitud:this.ContactData.longitud
            })
            .then(function() {
              alert('Contacto se ha actualizado!')
                console.log("Document successfully written!");
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });
          },

    },
    // Inicio Ciclo de vida
    // beforeCreate () {
    //   console.log('1 - beforeCreate')
    // },
    // created () {
    //   console.log('2 - created')
    // },
    // beforeMount () {
    //   console.log('3 - beforeMount')
    // },
    // mounted () {
    //   console.log('4 - mounted')
    // },
    // beforeUpdate () {
    //   console.log('5 - beforeUpdate')
    //   this.DataContact = this.ContactData
    //
    // },
    // Updated () {
    //   console.log('6 - Update')
    // },
  }
</script>

<style>
</style>
