<template>
    <div id="editnosotrosprops">

      <h3 class="text-center"   style="font-family: 'Times New Roman'"  ><b>NOSOTROS</b></h3>
      <mdb-container>
        <mdb-row>
          <mdb-col col="12" sm="12" md="12" lg="12" xl="12">
            <mdb-card>
              <mdb-card-image :src="NosotrosData.src" alt="Card image cap"></mdb-card-image>
              <input  placeholder="Seleciona tu imagen" type="file" @change="buscarImagen($event)">
            </mdb-card>
          </mdb-col>
          <mdb-col col="12" sm="12" md="12" lg="12" xl="12">
            <mdb-input v-model="NosotrosData.text"  type="textarea" label="Material textarea" :rows="NumerRow"/>
            <mdb-btn  color="green" @click="UpdateNosotros"   >Actualizar</mdb-btn>
          </mdb-col>
        </mdb-row>

      </mdb-container>
    </div>
</template>



<script>
import {auth, db, firebase, storage} from '@/firebase'
import {mdbContainer, mdbRow, mdbCol} from 'mdbvue';
import { mdbCard, mdbCardImage, mdbCardBody, mdbCardTitle, mdbCardText, mdbBtn } from 'mdbvue'
import { mdbInput } from 'mdbvue';
 import { mapActions,mapState } from 'vuex'
export default {
    name: 'editnosotrosprops',
    components: {
      mdbInput,
      mdbContainer,
      mdbRow,
      mdbCol,
      mdbCard,
			mdbCardImage,
			mdbCardBody,
			mdbCardTitle,
			mdbCardText,
			mdbBtn
    },
    data() {
      return {
        file:null,
        NumerRow:5,
      };
    },
    computed:{
      ...mapState(['NosotrosData'])

    },
    methods: {
      async UpdateNosotros(){
          var self = this
          if (self.NosotrosData.from == 'local'){
            console.log('diego desde EditNostors')
            console.log(this.file)
            const refImagen = storage.ref().child('Nosotros').child('nosotros.jpg')
            const res = await refImagen.put(self.file)
            // console.log(res)
            const urlDownload =  await refImagen.getDownloadURL()
            // console.log(urlDownload)
            db.collection("General").doc("Nosotros").set({
                      src: urlDownload,
                      from:'remote',
                      text:self.NosotrosData.text
                  })
                  .then(function() {
                    self.file= null
                    alert('Plantilla nosotros se ha actualizado!')
                      console.log("Document successfully written!");
                  })
                  .catch(function(error) {
                      console.error("Error writing document: ", error);
                  });
                }else {
                  db.collection("General").doc("Nosotros").set({
                            src: this.NosotrosData.src,
                            from:'remote',
                            text:self.NosotrosData.text
                        })
                        .then(function() {
                          self.file= null
                          alert('Plantilla nosotros se ha actualizado!')
                            console.log("Document successfully written!");
                        })
                        .catch(function(error) {
                            console.error("Error writing document: ", error);
                        });
                }
      },

      buscarImagen(event){
      var self = this
        this.file=event.target.files[0]
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload=(e)=> {
          self.NosotrosData.src = e.target.result
          self.NosotrosData.from = 'local'
        }
        console.log(self.NosotrosData)
      },
    },
    // Inicio Ciclo de vida
    // beforeCreate () {
    //   console.log('1 - beforeCreate from Nosotros ')
    // },
    // created () {
    //   console.log('2 - created from Nosotros')
    // },
    // beforeMount () {
    //   console.log('3 - beforeMount from Nosotros')
    // },
    // mounted () {
    //   console.log('4 - mounted from Nosotros')
    // },
    // beforeUpdate () {
    //   console.log('5 - beforeUpdate from Nosotros')
    // },
    // Updated () {
    //   console.log('6 - Update from Nosotros' )
    // },
  }
</script>

<style>
</style>
