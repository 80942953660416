<template>
  <div>
    <Whatsapp :id_general="null" ></Whatsapp>
    <NavBarGeneral></NavBarGeneral>
    <ComponentContact :id_general=null></ComponentContact>
  </div>
</template>


<script>
  import NavBarGeneral from "@/components/NavBarGeneral.vue"
  import Whatsapp from "@/components/Whatsapp.vue"
  import ComponentContact from "@/components/ComponentContact.vue"
  export default {
    name: 'Properties',
    components: {
      ComponentContact,
      NavBarGeneral,
      Whatsapp,
    },
    data() {
      return {
      };
    },
  }
</script>
