<template>
  <div>
    <!-- START *********************  HEADER AND NAVBAR  LATERAL Y NORMAL  **************-->
    <header >
      <div class="menu_bar" >
          <a v-on:click="ShowNavbar" href="#" class="bt-menu"> <span>
          <mdb-icon icon="align-justify "  class="white-text pr-3" aria-hidden="true"  size="1x" />
          </span>Menu</a>
      </div>
      <nav style="z-index: 1" v-bind:style="{left:NavLeftSize + '%'}">
        <ul>
          <li><a v-on:click="FShowPerfil">Mi Perfil</a></li>
          <!-- <li><a v-on:click="FShowDashboard">Mi Dasboard</a></li> -->
          <li><a v-on:click="FShowMessage">Mensajes</a></li>
          <li>
            <li> <a style="color:white" v-on:click="ActiveEdit = !ActiveEdit"> Editar </a></li>
            <ul v-show='ActiveEdit'  >
              <li  type="circle"><a style="color:white; padding-left:50px" v-on:click="FShowCarrucel">Carrucel</a></li>
              <li  type="circle"><a style="color:white; padding-left:50px"v-on:click="FShowProp">Propiedades</a></li>
              <li type="circle"><a style="color:white; padding-left:50px"v-on:click="FShowNosotros">Nosotros</a></li>
              <li type="circle"><a style="color:white; padding-left:50px"v-on:click="FShowContact">Contactanos</a></li>
            </ul>
          </li>
        <li><a v-on:click="out_session">Cerrar sesion</a></li>
        </ul>
      </nav>
    </header>
    <!-- END*********************  HEADER AND NAVBAR  LATERAL Y NORMAL  **************-->


    <section class="card_topic_1">

      <!-- START *********************  Mostrar Subplantillas **************-->
      <DataProps v-show="ShowAddProp || ShowEditProp"></DataProps>
        <!-- START *********************  Mostrar Subplantillas **************-->

      <!-- START ********************* Formato para la edicion del carrucel **************-->
      <div v-show = 'ShowCarrucel'>
        <h3 class="text-center"   style="font-family: 'Times New Roman'"  ><b>MI CARRUCEL</b></h3>
        <mdb-btn v-on:click="modalCarrucel= true"   > Agregar Nuevo</mdb-btn>
        <mdb-modal  centered size="lg" :show="modalCarrucel" @close="modal = false">
          <mdb-modal-header>
            <mdb-modal-title>Nuevo</mdb-modal-title>
          </mdb-modal-header>
          <mdb-modal-body class="text-center" >
              <img :src="urlTempCarrucel" alt="thumbnail" class="img-thumbnail"
                style="width: 200px">
              <input placeholder="Seleciona tu imagen" type="file" @change="buscarImagen($event)">
          </mdb-modal-body>
          <mdb-modal-footer>
            <mdb-btn color="secondary" @click="modalCarrucel = false">Close</mdb-btn>
            <mdb-btn color="primary" @click="UploadImgCarrucel" :disabled="fileCarrucel === null" >Save Image</mdb-btn>
          </mdb-modal-footer>
          <div v-if="fileCarrucel">
            <h4 class="text-center" style="font-family: 'Times New Roman'">{{ fileCarrucel.name}}</h4>
          </div>
        </mdb-modal>

        <mdb-container>
          <mdb-row >
            <mdb-col xl="4" sm="12" md="12" lg="6"  v-for="item in imgCarrucel" >
                <mdb-card style=" margin-top: 25px" >
                  <div class="d-flex justify-content-center">
                		<mdb-view hover>
                			<a href="#!">
                				<mdb-card-image
                					:src =item.src
                					alt="Card image cap"
                          class="text-center"
                          />
                				<mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
                			</a>
                		</mdb-view>
                  </div>
                  <div>
                </div>

              		<mdb-card-body class="text-center">
                    <h5 style="font-family: 'Times New Roman'" > <b> Name :</b> {{ item.img }}</h5>
                    <mdb-btn color="red" v-on:click ="DeleteImgCarrucel(item)" >Eliminar</mdb-btn>
                  </mdb-card-body>
            	  </mdb-card>
              </mdb-col>
          </mdb-row>
        </mdb-container>
      </div>
<!-- END ********************* Formato para la edicion del carrucel **************-->


    <!-- START ********************* LOADING  **************-->
        <!-- <mdb-row >
          <mdb-col col="12" >
            <mdb-container class=" text-center">

              <mdb-modal v-show="ActLoading" style="padding-top:60%"  scrollable>
                <div style=" background-color: transparent;">
                <mdb-modal-body >
                  <div  class="spinner-border" role="status" >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <h4>Subiendo datos ......</h4>
                </mdb-modal-body>
              </div>
              </mdb-modal>
            </mdb-container>
          </mdb-col>
        </mdb-row > -->
    <!-- END ********************* LOADING  **************-->



<!-- START ********************* Formato para la edicion  y agregar  propiedades **************-->
    <div v-show = 'ShowProps'>
        <h3 class="text-center"   style="font-family: 'Times New Roman'"  ><b>MIS PROPIEDADES</b></h3>
        <mdb-row >
          <mdb-col col="2" >
            <div   v-on:click="FShowPropsTable(false)" >
              <mdb-icon  v-show="ShowProps_table" icon="table " class="green-text pr-3" aria-hidden="true"   size="2x" />
            </div>
            <div v-on:click="FShowPropsTable(true)">
              <mdb-icon   v-show="!ShowProps_table" icon="align-justify "  class="green-text pr-3" aria-hidden="true"  size="2x" />
            </div>
          </mdb-col >
        </mdb-row >
        <mdb-row >
          <mdb-col col="5" >
            <mdb-btn v-on:click="ChangeValueBoolean({var:'ShowAddProp',val:true})"> Agregar Nuevo</mdb-btn>
          </mdb-col >
          <mdb-col col="5" >

            <mdb-btn  v-show="!ActSearch && !ShowProps_table" v-on:click="FunActBuscar" color="blue" > Buscar propiedad</mdb-btn>
          </mdb-col >
        </mdb-row >


        <div v-show="ActSearch && !ShowProps_table">
          <SearchProps></SearchProps>
        </div>


        <div v-show = 'ShowProps_table'>
          <tableProps></tableProps>
        </div>

        <div v-show="!ActSearch && !ShowProps_table">
          <ModalAllProps></ModalAllProps>
        </div>
      </div>

<!-- END ********************* Formato para la edicion  y agregar  propiedades **************-->
      <div v-show='ShowContact'>
        <EditContactProps></EditContactProps>
      </div>

      <div>
        <EditNosotrosProps v-show='ShowNosotros' ></EditNosotrosProps>
      </div>
      <div>
        <EditPerfil v-show='ShowPerfil' ></EditPerfil>
      </div>
      <div>
        <EditMessage v-show='ShowMessage' ></EditMessage>
      </div>
      <div>
        <EditDashboard v-show='ShowDashboard' ></EditDashboard>
      </div>
    </section>
  </div>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
import TableProps from "@/components/SubComponents/TableProps.vue"
import DataProps from "@/components/SubComponents/DataProps.vue"
import SearchProps from "@/components/SubComponents/SearchProps.vue"
import ModalAllProps from "@/components/SubComponents/ModalAllProps.vue"
import EditContactProps from "@/components/SubComponents/EditContactProps.vue";
import EditNosotrosProps from "@/components/SubComponents/EditNosotrosProps.vue";

import EditMessage from "@/components/SubComponents/EditMessage.vue";
import EditDashboard from "@/components/SubComponents/EditDashboard.vue";
import EditPerfil from "@/components/SubComponents/EditPerfil.vue";

import { mapActions,mapState } from 'vuex'
import { firebase, storage, db } from "@/firebase";
import { mdbContainer, mdbInput, mdbCard, mdbCardHeader, mdbCardTitle,
   mdbCardText, mdbCardBody, mdbIcon, mdbBtn, mdbRow, mdbCol, mdbCardImage, mdbView, mdbMask,
   mdbModal,mdbModalHeader,mdbModalTitle, mdbModalBody, mdbModalFooter,mdbCarousel,mdbFormInline} from "mdbvue";
export default {
    name: 'Inicio',
    components: {
      EditMessage,
      EditDashboard,
      EditPerfil,
      EditNosotrosProps,
      EditContactProps,
        ModalAllProps,
        SearchProps,
        DataProps,
        TableProps,
        mdbContainer,
        mdbInput,
        mdbCard,
        mdbCardHeader,
        mdbCardTitle,
        mdbCardBody,
        mdbCardText,
        mdbIcon,
        mdbBtn,
        mdbRow,
        mdbCol,
        mdbCardImage,
        mdbView,
        mdbMask,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbFormInline,

      },
    data(){
        return{
          //Activar nosotros
          ShowNosotros:false,
          //Activar mostrar buscado
          ActClean:true,
          ActBuscar:false,

          // mostrar plantialls a editar en el navegador
          ActiveEdit:false,
          NavLeftSize : 0,
          // Mostrar plnatilla carrucel para editar  y card de editar
          ShowCarrucel:false,
          modalCarrucel: false,
          urlTempCarrucel:'',
          fileCarrucel: null,
          // Mostrar plnatilla propiedades para editar  y card de editar
          ShowProps:false,
          // Muestra plantilla propiedades tipo tabla
          ShowProps_table:false,
          // Muestra plantilla propiedades tipo  contactanos
          ShowContact:false,
          // Muestra plantilla perfil
          ShowPerfil:true,
          // Muestra plantilla dashboard
          ShowDashboard:false,
          // Muestra plantilla
          ShowMessage:false,



        }
    },
    computed:{
        ...mapState(['dataMessages','usuario','imgCarrucel','dataProps','ShowEditProp','ShowAddProp','ActSearch']),

    },
    methods:{
      // Funciones principales  de mi Heeader para seleccionar los templates de edicion
      FShowCarrucel:function(){
        this.ShowCarrucel = true
        this.ShowProps = false
        this.ShowContact = false
        this.ShowNosotros = false
        this.ShowPerfil=false
        this.ShowDashboard=false
        this.ShowMessage=false
        },
      FShowProp:function(){
        this.ShowProps = true
        this.ShowCarrucel = false
        this.ShowContact = false
        this.ShowNosotros = false
        this.ShowPerfil=false
        this.ChangeValueBoolean({var:'Ejemplo',val:this.dataProps.slice()})
        this.ShowDashboard=false
        this.ShowMessage=false
        },
      FShowContact:function(){
        this.ShowContact = true
        this.ShowProps = false
        this.ShowCarrucel = false
        this.ShowNosotros = false
        this.ShowPerfil=false
        this.getContactData()
        this.ShowDashboard=false
        this.ShowMessage=false
        // this.getNosotrosData()
        },
      FShowNosotros: function(){
        // this.getContactData
        this.getNosotrosData()
        this.ShowContact = false
        this.ShowProps = false
        this.ShowCarrucel = false
        this.ShowNosotros = true
        this.ShowPerfil=false
        this.ShowDashboard=false
        this.ShowMessage=false
      },
      FShowPerfil:function(){
        this.ShowCarrucel = false
        this.ShowProps = false
        this.ShowContact = false
        this.ShowNosotros = false
        this.ShowPerfil=true
        this.ShowDashboard=false
        this.ShowMessage=false
        },
      FShowDashboard:function(){
        this.ShowCarrucel = false
        this.ShowProps = false
        this.ShowContact = false
        this.ShowNosotros = false
        this.ShowPerfil=false
        this.ShowDashboard=true
        this.ShowMessage=false
        },
      FShowMessage:function(){
        console.log('from MyPanel FShowMessage')
        this.readDataMessage()
        this.ShowCarrucel = false
        this.ShowProps = false
        this.ShowContact = false
        this.ShowNosotros = false
        this.ShowPerfil=false
        this.ShowDashboard=false
        this.ShowMessage=true
        console.log(this.dataMessages)
        },



      // Funciones Para el  funcionamiento  de edicion del Carrucel de imagenes principal
        buscarImagen(event){
        console.log(event.target.files[0]);
          this.fileCarrucel=event.target.files[0]
          const reader = new FileReader();
          reader.readAsDataURL(this.fileCarrucel);
          reader.onload=(e)=> {
            this.urlTempCarrucel = e.target.result
          }},
        async UploadImgCarrucel(){
          var self = this
          try{
            const refImagen = storage.ref().child('ImgCarrucel').child(this.fileCarrucel.name)
            const res = await refImagen.put(this.fileCarrucel)
            console.log(res)
            const urlDownload =  await refImagen.getDownloadURL()
            console.log(urlDownload)
            db.collection("Carrucel").add({
                  ImgName: this.fileCarrucel.name,
                  UrlDownload: urlDownload,
                })
                .then(function(docRef) {
                    console.log("Document written with ID: ", docRef.id);
                    self.modalCarrucel = false
                    self.CleaningGeneralData()
                })
                .catch(function(error) {
                    console.error("Error adding document: ", error);
                    alert("Error Upload Image")
                });
          }catch(error){
            console.log(error)
          }
        },
        async DeleteImgCarrucel(img){
          var desertRef = storage.ref().child("ImgCarrucel").child(img.img);
          desertRef.delete().then(function() {
              db.collection("Carrucel").doc(img.id).delete().then(function() {
                    console.log("Document successfully deleted!");
                }).catch(function(error) {
                    console.error("Error removing document: ", error);
                });
            }).catch(function(error) {
              console.log("AN error to try delete img carrucel")
            });
        },






      // Funciones Para el  funcionamiento  de edicion del las PROPIEDADES
      // muestra  y desactiva tabla al dar click sobre los iconos
      FShowPropsTable(value){
        if(value){
            this.ShowProps_table = true;
            this.ChangeValueBoolean({var:'statusTab',val:true})
        }else{
          this.ShowProps_table = false;
          this.ChangeValueBoolean({var:'statusTab', val:false})
        }
        this.tabDataProps();
      },





      // Funcion para activar el Searc(Buscador)
      FunActBuscar(){
        if(this.ActSearch == true){
          this.ChangeValueBoolean({var:'Ejemplo',val:this.dataProps.slice()})
        }else{
            this.search()
        }
        this.ChangeValueBoolean({var:'ActSearch', val:!this.ActSearch})
      },



      // Ottras funciones generales
      ShowNavbar: function(){
        if (this.NavLeftSize == 0 && screen.width < 800){
          this.NavLeftSize=-200
        }else{
          this.NavLeftSize=0
        }
      },
      CleaningGeneralData(){
        this.ActiveEdit=false
        this.urlTempCarrucel=''
        this.modalCarrucel= false
        this.fileCarrucel= null
      },
      ...mapActions(['ReadVisit','readDataMessage','getContactData','getNosotrosData','out_session','DeletePropFirebase','EditPropFirebase','getImgCarrucel','getProps','tabDataProps','ChangeValueBoolean','search','getLogo'])
    },



    mounted(){
      console.log("Estoy en monutend from MYPANEL")
      this.ChangeValueBoolean({var:'Ejemplo',val:this.dataProps.slice()})
      this.getImgCarrucel();
      this.getProps();
      this.getLogo();
      this.ReadVisit();

    },


}

</script>
















<style>
*{
  margin:0;
  padding:0;
}
body{
  background:#FFFFFA;
}
header{
  width:100%
}

header nav{
  width:70%;
  max-width:1000px;
  background:#024959;
  left:0;

  height:100%;
  margin:0;
  position:fixed;
}

header nav ul {
  overflow:hidden;
  list-style: none;
}

header nav ul li{
  float:left;
  display:block;
  float:none;
  border-bottom:1px solid rgba(255,255,255,.3);
}

header nav ul li a{
  color:#fff;
  padding:20px;
  display:block;
  text-decoration:none;
}
header nav ul li span {
  margin-right:10px;
}

header nav ul li a:hover{
  background:#037E8C;
}
section{
  width:100%;
  padding-left:25px;
  padding-right:25px;
  padding-top:10px;
}
.menu_bar{
  display:block;
  width: 100%;
  background:#ccc;
}

.menu_bar a{
    font-size:30px;
    display:block;
    padding:20px;
    background:#024959;
    color: #fff;
    text-decoration:none;
    font-weight:bold;
    font-size:25px;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;

  }

  .menu_bar span{
    float:right;
    font-size:30px;
  }

  .card_topic_1{
    padding-left: 22px

  }
  .ImageCarrusel{
    width: 200px
  }
@media screen and (min-width:500px){
  .card_topic_1{
    padding-left: 220px
  }
  .ImageCarrusel{
    width:500px;
  }
  header nav{
    width:200px;
  }
  section{
    width:100%;
    padding-left:25px;
    padding-right:25px;
    padding-top:10px;
  }
}

.background_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  background-position: center top;

}
</style>
