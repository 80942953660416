<template>
    <div id="searchprops">
      <mdb-row style="padding-top:22px" >
            <mdb-col col="11" sm="12" md="6" lg="6" xl="6" >
              <input  @keyup="FiltrarPropGeneral"  v-model="PropSearch" class="form-control" type="text" placeholder="Busqueda por ID"/>
            </mdb-col >
          </mdb-row >

          <mdb-row style="padding-top:10px">
            <mdb-col col="11"  sm="6" md="6" lg="3" xl="3">
              <select v-on:click="FiltrarPropGeneral" v-model="PropLocalidad" class="browser-default custom-select">
                <option :value="null" selected> Localidad </option>
                <option value="cuernavaca">Cuernavaca</option>
                <option value="jiutepec">Jiutepec</option>
              </select>
            </mdb-col>
            <mdb-col col="11"  sm="6" md="6" lg="3" xl="3">
              <select v-on:click="FiltrarPropGeneral" v-model="PropPropiedad" class="browser-default custom-select">
                <option  :value="null" selected>Propiedad</option>
                <option value="casa">Casa</option>
                <option value="departamento">Departamento</option>
              </select>
            </mdb-col>
            <mdb-col  col="11"  sm="6" md="4" lg="2" xl="2">
              <select v-on:click="FiltrarPropGeneral" v-model="PropVentaRenta" class="browser-default custom-select">
                <option :value="null" selected>Venta o Renta</option>
                <option value="renta">Renta</option>
                <option value="venta">Venta</option>
              </select>
            </mdb-col>
            <mdb-col col="11"  sm="6" md="4" lg="2" xl="2">
              <input  v-on:click="FiltrarPropGeneral" @keyup="FiltrarPropGeneral" v-model="PropPrecioMin" value="0" class="form-control" type="number" placeholder="Precio minimo"/>
            </mdb-col>
            <mdb-col col="11"  sm="6" md="4" lg="2" xl="2">
              <input  v-on:click="FiltrarPropGeneral" @keyup="FiltrarPropGeneral" v-model="PropPrecioMax" value="0" class="form-control" type="number" placeholder="Precio Maximo"/>
            </mdb-col>
          </mdb-row >
          <mdb-row  style="padding-top:10px">
            <mdb-col col="5"  sm="6" md="3" lg="3" xl="3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon">
                  <mdb-icon icon="bath" />
                </span>
                <input v-on:click="FiltrarPropGeneral" @keyup="FiltrarPropGeneral" v-model="PropItemBath" value="0" type="number"   class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon">
              </div>
            </mdb-col>

            <mdb-col col="5" sm="6" md="3" lg="3" xl="3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon">
                  <mdb-icon icon="bed" />
                </span>
                <input v-on:click="FiltrarPropGeneral" @keyup="FiltrarPropGeneral" v-model="PropItemBed" value="0" type="number"   class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon">
              </div>
            </mdb-col>

            <mdb-col col="5" sm="6" md="3" lg="3" xl="3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon">
                  <mdb-icon icon="car" />
                </span>
                <input v-on:click="FiltrarPropGeneral" @keyup="FiltrarPropGeneral" v-model="PropItemCar" value="0" type="number"   class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon">
              </div>
            </mdb-col>

            <mdb-col col="5" sm="6" md="3" lg="3" xl="3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon">
                  <img src="@/assets/terreno.png" width="22" height="22">
                  <!-- <link rel="shortcut icon" type="image/png" src="../assets/terreno.png"/> -->
                  <!-- <mdb-icon src="../assets/terreno.png" /> -->
                </span>
                <input v-on:click="FiltrarPropGeneral" @keyup="FiltrarPropGeneral" v-model="PropItemTerreno" value="0" type="number"   class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon">
              </div>
            </mdb-col>
          </mdb-row >
          <ModalAllProps>  </ModalAllProps>

    </div>
</template>



<script>
import ModalAllProps from "@/components/SubComponents/ModalAllProps.vue";
import { mapActions,mapState } from 'vuex';
import {
      mdbContainer,
      mdbInput,
      mdbCard,
      mdbCardHeader,
      mdbCardTitle,
      mdbCardBody,
      mdbCardText,
      mdbIcon,
      mdbBtn,
      mdbRow,
      mdbCol,
      mdbCardImage,
      mdbView,
        mdbMask,} from "mdbvue";

  export default {
    name: 'searchprops',
    components: {
      ModalAllProps,
      mdbContainer,
      mdbInput,
      mdbCard,
      mdbCardHeader,
      mdbCardTitle,
      mdbCardBody,
      mdbCardText,
      mdbIcon,
      mdbBtn,
      mdbRow,
      mdbCol,
      mdbCardImage,
      mdbView,
        mdbMask,
    },
    data() {
      return {
        //Activar mostrar buscado
        ActClean:true,
        // Valor en tiempo real del buscado general
        PropSearch:"",
        PropLocalidad:null,
        PropVentaRenta:null,
        PropPropiedad:null,
        PropPrecioMin:null,
        PropPrecioMax:null,
        PropItemBath: 0,
        PropItemBed:0,
        PropItemCar:0,
        PropItemTerreno:0,
        result:null,
        VarPropSearch:[],
      };
    },
    computed:{
      ...mapState(['dataProps','ShowEditProp','ShowAddProp','Ejemplo','ParamSearch']),
    },
    methods: {
      FiltrarPropGeneral(event) {
        console.log("denis holiiiii")
        console.log(this.PropPropiedad)
        this.ChangeValueBoolean({var:'ParamSearch.TxtPropSearch',val: this.PropSearch.toLowerCase()})
        this.ChangeValueBoolean({var:'ParamSearch.PropLocalidad',val: this.PropLocalidad})
        this.ChangeValueBoolean({var:'ParamSearch.PropPropiedad',val: this.PropPropiedad})
        this.ChangeValueBoolean({var:'ParamSearch.PropVentaRenta',val: this.PropVentaRenta})
        this.ChangeValueBoolean({var:'ParamSearch.PropPrecioMin',val: this.PropPrecioMin})
        this.ChangeValueBoolean({var:'ParamSearch.PropPrecioMax',val: this.PropPrecioMax})
        this.ChangeValueBoolean({var:'ParamSearch.PropItemBath',val: this.PropItemBath})
        this.ChangeValueBoolean({var:'ParamSearch.PropItemBed',val: this.PropItemBed})
        this.ChangeValueBoolean({var:'ParamSearch.PropItemCar',val: this.PropItemCar})
        this.ChangeValueBoolean({var:'ParamSearch.PropItemTerreno',val: this.PropItemTerreno})
        this.search()
      },



      ...mapActions(['DeletePropFirebase','EditPropFirebase','getProps','ChangeValueBoolean','search'])


    },
    // Inicio Ciclo de vida
    // beforeCreate () {
    //   console.log('1 - beforeCreate')
    // },
    // created () {
    //   console.log('2 - created')
    // },
    // beforeMount () {
    //   console.log('3 - beforeMount')
    // },
    // mounted () {
    //   console.log('4 - mounted')
    // },
    // beforeUpdate () {
    //   console.log('5 - beforeUpdate')
    // },
    // updated () {
    //   console.log('6 - beforeUpdate')
    // },
    // beforeDestroy () {
    //   console.log('7 - beforeDestroy')
    // },
    // destroyed () {
    //   console.log('8 - destroyed')
    // },
    // Fin Ciclo de vida
  }
</script>

<style>
</style>
