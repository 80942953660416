<template>
  <div>
    <Whatsapp :id_general="PropData.DataProp.id_general"></Whatsapp>
    <div id="inicio">
      <NavBarGeneral></NavBarGeneral>
    </div>
    <mdb-container>
      <!-- {{ $route.params.id }} -->
      <!-- {{PropData}} -->
      <mdb-card>
        <mdb-carousel :interval="8000" slide :items="PropData.DataProp.src" indicators controlls></mdb-carousel>
        <mdb-card-body>
          <mdb-card-title><b>$ {{ PropData.DataProp.precio }} </b></mdb-card-title>
          <mdb-card-text><b>{{PropData.DataProp.name}}</b></mdb-card-text>
          <mdb-card-text><mdb-icon icon="map-marker-alt" />{{PropData.DataProp.direccion}}</mdb-card-text>
          <mdb-card-title><b>Detalles</b></mdb-card-title>
          <mdb-container v-if="PropData.DataProp.type == 'Casa' || PropData.DataProp.type == 'Departamento'">
              <mdb-row>
                <mdb-col v-show="PropData.DataProp.Baño>0" col="6" sm="6" md="6" lg="6" xl="6" >
                    <span id="basic-addon">
                      <mdb-icon icon="bath" />
                      <p> {{ PropData.DataProp.Baño }} Baños </p>
                    </span>
                </mdb-col>
                <mdb-col v-show="PropData.DataProp.MedioBaño>0" col="6" sm="6" md="6" lg="6" xl="6" >
                    <span id="basic-addon">
                      <mdb-icon icon="bath" />
                      <p> {{ PropData.DataProp.MedioBaño }}  Baño 1/2 </p>
                    </span>
                </mdb-col>
                <mdb-col  v-show="PropData.DataProp.Habitacion > 0" col="6" sm="6" md="6" lg="6" xl="6">
                    <span id="basic-addon">
                      <mdb-icon icon="bed" />
                      <p>{{ PropData.DataProp.Habitacion }} Recamaras</p>
                    </span>
                </mdb-col>
                <mdb-col v-show="PropData.DataProp.Garaje > 0" col="6" sm="6" md="6" lg="6" xl="6" >
                    <span id="basic-addon">
                      <mdb-icon icon="car" />
                      <p>{{PropData.DataProp.Garaje}} Garage </p>
                    </span>
                </mdb-col>

              <mdb-col v-show="PropData.DataProp.Terreno>0" col="6" sm="6" md="6" lg="6" xl="6">
                  <span id="basic-addon">
                    <img src="@/assets/terreno.png" width="22" height="22">
                    <p> {{PropData.DataProp.Terreno}} m<sup>2</sup> Terreno</p>
                  </span>
              </mdb-col>

              <mdb-col  v-show="PropData.DataProp.mConstruccion>0" col="6" sm="6" md="6" lg="6" xl="6">
                  <span id="basic-addon">
                    <mdb-icon icon="home" />
                    <p> {{PropData.DataProp.mConstruccion}} m<sup>2</sup> Construidos</p>
                  </span>
              </mdb-col>
          </mdb-row>
          </mdb-container>

          <mdb-container v-if="PropData.DataProp.type == 'Bodega'">
            <mdb-row>
              <mdb-col v-show="PropData.DataProp.Baño>0" col="6" sm="6" md="6" lg="6" xl="6" >
                  <span id="basic-addon">
                    <mdb-icon icon="bath" />
                    <p> {{ PropData.DataProp.Baño }} Baño </p>
                  </span>
              </mdb-col>
              <mdb-col  v-show="PropData.DataProp.Estacionamiento > 0" col="6" sm="6" md="6" lg="6" xl="6">
                  <span id="basic-addon">
                    <mdb-icon icon="car" />
                    <p>{{ PropData.DataProp.Estacionamiento }} Estacionamiento</p>
                  </span>
              </mdb-col>


            <mdb-col v-show="PropData.DataProp.Terreno>0" col="6" sm="6" md="6" lg="6" xl="6">
                <span id="basic-addon">
                  <img src="@/assets/terreno.png" width="22" height="22">
                  <p> {{PropData.DataProp.Terreno}} m<sup>2</sup> Terreno</p>
                </span>
            </mdb-col>

            <mdb-col  v-show="PropData.DataProp.mConstruccion>0" col="6" sm="6" md="6" lg="6" xl="6">
                <span id="basic-addon">
                  <mdb-icon icon="home" />
                  <p> {{PropData.DataProp.mConstruccion}} m<sup>2</sup>Construidos</p>
                </span>
            </mdb-col>
        </mdb-row>
          </mdb-container>


          <mdb-container v-if="PropData.DataProp.type =='Terreno'">
            <mdb-row>
            <mdb-col v-show="PropData.DataProp.Terreno>0" col="6" sm="6" md="6" lg="6" xl="6">
                <span id="basic-addon">
                  <img src="@/assets/terreno.png" width="22" height="22">
                  <p> {{PropData.DataProp.Terreno}} m<sup>2</sup> Terreno</p>
                </span>
            </mdb-col>
          </mdb-row>
        </mdb-container>

        <mdb-container v-if="PropData.DataProp.type =='Oficina' || PropData.DataProp.type =='LocalComercial'">
          <mdb-row>
          <mdb-col v-show="PropData.DataProp.Terreno>0" col="6" sm="6" md="6" lg="6" xl="6">
              <span id="basic-addon">
                <img src="@/assets/terreno.png" width="22" height="22">
                <p> {{PropData.DataProp.Terreno}} m<sup>2</sup> Terreno</p>
              </span>
          </mdb-col>
        </mdb-row>
        <mdb-col  v-show="PropData.DataProp.Estacionamiento > 0" col="6" sm="6" md="6" lg="6" xl="6">
            <span id="basic-addon">
              <mdb-icon icon="car" />
              <p>{{ PropData.DataProp.Estacionamiento }} Estacionamientos</p>
            </span>
        </mdb-col>
      </mdb-container>
      <mdb-card-title><b>Descripcion</b></mdb-card-title>
      <mdb-container>
        <mdb-row>
          <mdb-col col="12">
            <span><b>Tipo de propiedad:</b>{{PropData.DataProp.type}}</span>
          </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col col="12">
          <span v-show ="PropData.DataProp.renta == true"><b>Estado:</b>En renta</span>

        </mdb-col>
        <mdb-col col="12">
          <span v-show ="PropData.DataProp.venta == true"><b>Estado:</b>En venta</span>

        </mdb-col>


    </mdb-row>
    <br>
      <mdb-row>
        <mdb-col col="12">
        {{PropData.DataProp.descripcion}}
        </mdb-col>
    </mdb-row>
    </mdb-container>





        </mdb-card-body>
      </mdb-card>
    </mdb-container>
    <br>

    <mdb-container>
      <div class="row">
         <GoogleMap :latitude =PropData.DataProp.latitud :longitude= PropData.DataProp.longitud :title="'Titulo Marcador'" />
      </div>
      <br>
    <ComponentContact :id_general="PropData.DataProp" > </ComponentContact>
    </mdb-container>
    <br>
    <mdb-container>
        <mdb-card-title class="text-left Texto3">Propiedades Similares</mdb-card-title>
        <mdb-container>
          <mdb-row>
            <mdb-col  sm="12" md="6" lg="4" xl="4" v-for="(item, index) in PropRelevant">
              <mdb-container v-show="item.id_general !=  PropData.DataProp.id_general"  v-if="item.control_active == true">
              <mdb-row>
                <mdb-card-group>
                  <mdb-card style="margin-top:50px">
                    <mdb-view hover>
                      <a href="#!">
                        <img @click="GoToPropertie(item.DataProp.id_general)" class="img1" :src="item.src[0].src" height="200"	alt="Card image cap">
                      </a>
                    </mdb-view>
                    <mdb-card-body>
                      <mdb-card-title class="Texto4"  style=" font-weight: bold; color:#689f38  ;">{{item.name}} </mdb-card-title>
                      <p><h5 style="font-weight: bold; color:#000000"  class="text-left">$ {{item.precio}}</h5></strong> </p>
                      <mdb-container>
                        <mdb-row>
                          <mdb-col col="5" sm="5" md="5" lg="5" xl="6" >
                              <span id="basic-addon">
                                <mdb-icon icon="bath" />
                                <p>{{ item.Baño }} Baños </p>
                              </span>
                          </mdb-col>
                          <mdb-col col="7" sm="7" md="7" lg="7" xl="6">
                              <span id="basic-addon">
                                <mdb-icon icon="bed" />
                                <p>{{ item.Habitacion }} Recamaras</p>
                              </span>
                          </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col col="5" sm="5" md="5" lg="5" xl="6" >
                            <span id="basic-addon">
                              <mdb-icon icon="car" />
                              <p>{{ item.Garaje }} Garage </p>
                            </span>
                        </mdb-col>
                        <mdb-col col="7" sm="7" md="7" lg="7" xl="6">
                            <span id="basic-addon">
                              <img src="@/assets/terreno.png" width="22" height="22">
                              <p>{{ item.Terreno }} m2</p>
                            </span>
                        </mdb-col>
                    </mdb-row>
                    </mdb-container>
                      <mdb-card-text></mdb-card-text>

                     <mdb-btn @click="GoToPropertie(item.id_general)" color="green">Mas Información</mdb-btn>
                    </mdb-card-body>
                  </mdb-card>
                </mdb-card-group>
              </mdb-row>
            </mdb-container>

            </mdb-col sm="4">
            <br>
          </mdb-row>
        </mdb-container>
        </mdb-card>
        <mdb-btn size="lg" @click="siguiente" :disabled="ActiveBtnNext"  class="float-center"  style="background-color: #00796b " > <strong> Ver mas </strong> </mdb-btn>


      </mdb-container>


    <!-- <FindProperties></FindProperties>
    <SearchProperties></SearchProperties> -->
  </div>
</template>


<script>
import GoogleMap from "@/components/GoogleMap";
import { firebase, storage, db } from "@/firebase";
import { mdbIcon } from 'mdbvue';
import ComponentContact from "@/components/ComponentContact.vue"
import { mdbCarousel,mdbRow, mdbCol, mdbContainer} from "mdbvue";
import {  mdbCard,
          mdbCardImage,
          mdbCardHeader,
          mdbCardBody,
          mdbCardTitle,
          mdbCardText,
          mdbCardFooter,
          mdbCardUp,
          mdbCardAvatar,
          mdbCardGroup,
          mdbBtn,
          mdbView,
          mdbMask } from 'mdbvue';
import NavBarGeneral from "@/components/NavBarGeneral.vue"
import Whatsapp from "@/components/Whatsapp.vue"
import FindProperties from "@/components/FindProperties.vue"
import SearchProperties from  "@/components/SearchProperties.vue"
import router from '../router'
import { mapActions,mapState } from 'vuex'
  export default {
    name: 'Properties',
    components: {
      GoogleMap,
      ComponentContact,
      mdbIcon,
      mdbContainer,
       mdbCarousel,
       mdbRow,
       mdbCol,
      SearchProperties,
      FindProperties,
      NavBarGeneral,
      Whatsapp,
      mdbCard,
			mdbCardImage,
			mdbCardBody,
			mdbCardTitle,
			mdbCardText,
			mdbBtn,
      mdbCardHeader,
      mdbCardFooter,
      mdbCardUp,
      mdbCardAvatar,
      mdbCardGroup,
      mdbView,
      mdbMask,
    },
    data() {
      return {
        styles: [
        {
          "featureType": "administrative",
          "elementType": "all",
          "stylers": [{
            "visibility": "off"
          }]
        },
        {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [{
            "visibility": "simplified"
          }]
        },
        {
          "featureType": "road",
          "elementType": "all",
          "stylers": [{
            "visibility": "simplified"
          }]
        },
        {
          "featureType": "water",
          "elementType": "all",
          "stylers": [{
            "visibility": "simplified"
          }]
        },
        {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [{
            "visibility": "simplified"
          }]
        },
        {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [{
            "visibility": "simplified"
          }]
        },
        {
          "featureType": "road.highway",
          "elementType": "all",
          "stylers": [{
            "visibility": "off"
          }]
        },
        {
          "featureType": "road.local",
          "elementType": "all",
          "stylers": [{
            "visibility": "on"
          }]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [{
            "visibility": "on"
          }]
        },
        {
          "featureType": "road.arterial",
          "elementType": "all",
          "stylers": [{
            "visibility": "off"
          }]
        },
        {
          "featureType": "water",
          "elementType": "all",
          "stylers": [{
            "color": "#5f94ff"
          },
          {
            "lightness": 26
          },
          {
            "gamma": 5.86
          }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "all",
          "stylers": [{
            "weight": 0.6
          },
          {
            "saturation": -85
          },
          {
            "lightness": 61
          }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [{
            "hue": "#0066ff"
          },
          {
            "saturation": 74
          },
          {
            "lightness": 100
          }
          ]
        }
      ],
        PropData:[],
        PropRelevant:[],
        IndexEnd:0,
        ActiveBtnNext:false,
      };
    },
    methods:{
      ...mapActions(['ChangeValueBoolean']),
      GoToPropertie(id_general){
        var self = this
        console.log(id_general)
        self.$router.push({ name: 'Propiedad', params: { id: id_general }})
        self.$router.go()
      },
      siguiente(){
        console.log("Hi from siguiente ")
        var self= this
        var search = db.collection("Propiedades")
        .where('DataProp.type',"==", self.PropData.DataProp.type)
        .limit(6)
        .startAfter(self.IndexEnd)
        .get()
        .then(function(querySnapshot) {
          var index = querySnapshot.docs[querySnapshot.docs.length -1];
          self.IndexEnd =  index


          var DataPropsFrontend = self.PropRelevant
          // var DataProps
          querySnapshot.forEach(function(doc) {
            DataPropsFrontend.push(doc.data())
            console.log(doc.id, " => ", doc.data());
          });
          self.PropRelevant= DataPropsFrontend
        })
        .then(()=>{
          var search1 = db.collection("Propiedades")
          .where('DataProp.type',"==", self.PropData.DataProp.type)
          .limit(6)
          .startAfter(self.IndexEnd)
          .get()
          .then(function(querySnapshot) {
                if(querySnapshot.empty)
                    self.ActiveBtnNext = true

              });

        })
      },
    },


    // Inicio Ciclo de vida
    // beforeCreate () {
    //   console.log('1 - beforeCreate from Propiedad.vue')
    // },
    created () {
      console.log('2 - created from Propiedad.vue')
      var self = this
      self.ChangeValueBoolean({var:'ActContact',val:true})

      console.log(this.$route.params.id)
      var docRef = db.collection("Propiedades").doc(this.$route.params.id);
      docRef.get().then(function(doc) {
          if (doc.exists) {
                // console.log("Document data:", doc.data());
                self.PropData = doc.data()
                db.collection("Propiedades")
                .where('DataProp.type',"==", self.PropData.DataProp.type)
                .limit(6)
                .get()
                .then(function(querySnapshot) {
                  self.IndexEnd = querySnapshot.docs[querySnapshot.docs.length -1];
                    querySnapshot.forEach(function(doc) {
                      // console.log(doc.id, " => ", doc.data());
                      self.PropRelevant.push(doc.data().DataProp)
                    });
                })
                .then(()=>{
                  var search1 = db.collection("Propiedades")
                  .where('DataProp.type',"==", self.PropData.DataProp.type)
                  .limit(6)
                  .startAfter(self.IndexEnd)
                  .get()
                  .then(function(querySnapshot) {
                        if(querySnapshot.empty)
                            self.ActiveBtnNext = true

                      });

                })
                .catch(function(error) {
                    console.log("Error getting documents: ", error);
                });
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
    },
    beforeMount () {
      console.log('3 - beforeMount from Propiedad.vue')
    },
    mounted () {
      console.log('4 - mounted from Propiedad.vue')

    },
    beforeUpdate () {
      console.log('5 - beforeUpdate from Propiedad.vue')
    },
    updated () {
      console.log("6-Update from Propiedad.vue ")
    },
    beforeDestroy () {
      console.log('7 - beforeDestroy from Propiedad.vue')
    },
    destroyed () {
      console.log('8 - destroyed from Propiedad.vue')
    }
    // Fin Ciclo de vida
  }
</script>

<style>
.Texto3{
    font-family: "Segoe UI";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 25px;
  }
</style>
