<template>
    <div id="editmessage">
      <h3 class="text-center"   style="font-family: 'Times New Roman'"  ><b>Mis Mensajes</b></h3>

        <mdb-tbl responsive>
          <mdb-tbl-head color="green lighten-3">
            <tr>
              <th>#</th>
              <th>Fecha</th>
              <th>Nombre</th>
              <th>Telefono</th>
              <th>Email</th>
              <th>Mensaje</th>
              <th>ID</th>
              <th>Control</th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr v-for="(item, index) in dataMessages" scope="row">
              <th scope="row">{{index + 1}}</th>
              <td>{{new Date(item.time).toLocaleDateString("en-US") }}</td>
              <td>{{item.name}}</td>
              <td  v-on:click="SendWhatsapp(item.tel)" style="color:blue">{{item.tel}}</td>
              <td>{{item.email}}</td>

              <td>{{item.message}}</td>

              <td v-on:click="DirProp(item.Id)" style="color:blue">{{item.Id_prop}}</td>
              <td style="color:red" v-on:click="DeleteMessage(item.id_general)">Eliminar</td>
            </tr>
          </mdb-tbl-body>
      </mdb-tbl>
      <mdb-btn  v-on:click="Siguiente"  :disabled="ActBtnNext_Message" outline="success">Ver mas</mdb-btn>
        <mdb-btn  v-on:click="Download" outline="success">Descargar</mdb-btn>
    </div>
</template>


<script>
import XLSX from 'xlsx';
import jspdf from 'jspdf';
import {auth, db, firebase, storage} from '@/firebase';
import { mapActions,mapState } from 'vuex'
import { mdbTbl, mdbTblHead, mdbTblBody } from 'mdbvue';
import {mdbPagination, mdbPageItem, mdbPageNav, mdbBtn} from 'mdbvue';
export default {
    name: 'editdashboard',
    components: {
       mdbBtn,
      mdbTbl,
      mdbTblHead,
      mdbTblBody,
      mdbPagination,
     mdbPageItem,
     mdbPageNav
    },
    data() {
      return {
      };
    },
    methods:{
      ...mapActions(['ChangeValueBoolean','readDataMessage']),
      Download(){
        console.log("Descargar")
        var doc = new jspdf('p','pt');
        doc.autoTable({
          columnStyles: { 0: { halign: 'center',fontSize:13,fontStyle:'bold'} }, // Cells in first column centered and green
          body: [['Mis Mensajes']],
        })
        var columns = ["ID" , "Fecha", "Nombre", "Telefono" , "Email","Mensaje"];
        var rows = [];
        for (let step = 0; step < this.dataMessages.length; step++) {
            // Se ejecuta 5 veces, con valores del paso 0 al 4.
            var data=[]
            // data.push(step + 1)
            data.push(this.dataMessages[step].Id_prop)
            data.push(new Date(this.dataMessages[step].time).toLocaleDateString("en-US"))
            data.push(this.dataMessages[step].name)
            data.push(this.dataMessages[step].tel)
            data.push(this.dataMessages[step].email)
            data.push(this.dataMessages[step].message)
            rows.push(data)

          }
        doc.autoTable(columns, rows,{
          styles: {columnWidth: 85},
          });
        doc.save('MisMensajes.pdf');

      },
      DeleteMessage(id){
        console.log(id)
        var self = this
        db.collection("Messages").doc(id).delete().then(function() {
              console.log("Document successfully deleted!");
              self.readDataMessage()
              alert('Mensaje Eliminado')
          }).catch(function(error) {
              console.error("Error removing document: ", error);
          });




      },
      SendWhatsapp(tel){
        var self = this
        var   dir="https://api.whatsapp.com/send?phone=+52" + tel + "&text=Hola!&nbsp;"
        location.href=dir
        // self.$router.push('home')
      },
      DirProp(path){
        var self = this
        var   dir="http://www.novoinmobiliaria.com.mx/propiedad/" + path
        location.href=dir
      },
      Siguiente(){
        // console.log("Hi from siguiente ")
        var self= this
        var search = db.collection("Messages")
        .orderBy("time","desc")
        .limit(15)
        .startAfter(self.Message_last)
        .get()
        .then(function(querySnapshot) {
          var index = querySnapshot.docs[querySnapshot.docs.length -1];
          self.ChangeValueBoolean({var:'Message_last',val:index})

          var DataMessages = self.dataMessages
          // var DataProps
          querySnapshot.forEach(function(doc) {
             DataMessages.push(doc.data())
          });
          self.ChangeValueBoolean({var:'dataMessages',val:DataMessages})
          // self.PropRelevant= DataPropsFrontend
        })
        .then(()=>{
          var search1 = db.collection("Messages")
          .orderBy("time","desc")
          .limit(15)
          .startAfter(self.Message_last)
          .get()
          .then(function(querySnapshot) {
                if(querySnapshot.empty){
                self.ChangeValueBoolean({var:'ActBtnNext_Message',val:true})
              }


              });

        })
      }
    },
    computed:{
      ...mapState(['dataMessages','Message_last','ActBtnNext_Message'])
    },

  };
</script>
