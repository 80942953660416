<template>
  <div id="dataprops">
      <mdb-modal centered size="lg" >
      <mdb-modal-header>
        <mdb-modal-title>Nuevo</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body style="text-align: center;" >
        <mdb-row >
          <mdb-col col ="6" xl="6" sm="6" md="6" lg="6" >
            <mdb-input  v-model="DataProp.id" type="text"  label="Id"/>
          </mdb-col>
        </mdb-row >

        <mdb-row >
        </mdb-row >

        <mdb-carousel :interval="8000" slide :items="DataPropSrcTem" controlls>
        </mdb-carousel>
          <!-- <img :src="urlTempCarrucel" alt="thumbnail" class="img-thumbnail"
            style="width: 200px;"> -->
      </mdb-modal-body>
      <div style="text-align: left; padding-left:22px; padding-right:22px">
        <input  placeholder="Seleciona tu imagen" multiple="true" type="file" @change="buscarImagen($event)">
        <mdb-row  style="padding-top:22px;">
          <mdb-col col ="10" >
            <mdb-btn  color="primary" @click="AddPropsSrc"  :disabled="DataProp.id === null" >Agregar Imagen</mdb-btn>
          </mdb-col>
        </mdb-row >
        <mdb-row  v-for="(item,index) in DataPropSrcTem ">
          <mdb-col col ="7" >
            <mdb-list-group-item>{{ item.name }}</mdb-list-group-item>
          </mdb-col>
          <mdb-col col ="4">
            <mdb-btn color="red" @click="DeletePropsImg(index)">Delete</mdb-btn>
          </mdb-col>
        </mdb-row >
        <mdb-row  style="padding-top:22px;">
          <mdb-col col ="2" >
            Fecha:
          </mdb-col>
          <mdb-col col ="7" >
            <datepicker  @closed="ConvDateToTimeStamp" v-model="TimeNormal" name="birthdate"></datepicker>
          </mdb-col>
        </mdb-row >
        <mdb-row >
          <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
            <div class="custom-control custom-checkbox">
              <input  v-model="DataProp.renta" type="checkbox" class="custom-control-input"  id="Renta">
              <label class="custom-control-label" for="Renta">Renta</label>
            </div>
          </mdb-col>
          <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3">
            <div class="custom-control custom-checkbox">
              <input  v-model="DataProp.venta" type="checkbox" class="custom-control-input" id="Venta">
              <label class="custom-control-label" for="Venta">Venta</label>
            </div>
          </mdb-col>
        </mdb-row >
        <mdb-row >
          <mdb-col col ="12">
            <mdb-input v-model="DataProp.asesor" label="Asesor"/>
          </mdb-col>
          <mdb-col col ="12">
              <mdb-input v-model="DataProp.name" label="Nombre"/>
          </mdb-col>
          <mdb-col col ="12" xl="3" sm="4" md="4" lg="3">
            <mdb-input v-model="DataProp.direccion" label="Dirección"/>
          </mdb-col>
          <mdb-col col ="12" xl="3" sm="4" md="4" lg="3">
            <mdb-input v-model="DataProp.calle" label="Calle"/>
          </mdb-col>
          <mdb-col col ="12" xl="3" sm="4" md="4" lg="3">
            <mdb-input v-model="DataProp.numeroInt" label="Numero interior"/>
          </mdb-col>
          <mdb-col col ="12" xl="3" sm="4" md="4" lg="3">
            <mdb-input v-model="DataProp.numeroExt" label="Numero exterior"/>
          </mdb-col>
          <mdb-col col ="12" xl="3" sm="4" md="4" lg="3">
            <mdb-input v-model="DataProp.colonia" label="Colonia"/>
          </mdb-col>
          <mdb-col col ="12" xl="3" sm="4" md="4" lg="3">
            <mdb-input v-model="DataProp.cp" label="CP"/>
          </mdb-col>
          <mdb-col col ="12" xl="3" sm="4" md="4" lg="3">
            <mdb-input v-model="DataProp.ciudad" label="Ciudad"/>
          </mdb-col>
          <mdb-col col ="12" xl="3" sm="4" md="4" lg="3">
            <mdb-input v-model="DataProp.estado" label="Estado"/>
          </mdb-col>
          <mdb-col col ="12" >
            <mdb-input v-model="DataProp.descripcion"  type="textarea"  :rows="3" label="Descripcion"/>
          </mdb-col>
        </mdb-row >
        <select v-model="DataProp.escritura_publica" class="browser-default custom-select">
          <option selected value="">Select Escritura Publica</option>
          <option value="Cesion">Cesion</option>
          <option value="Titulo">Titulo</option>
        </select>

        <mdb-input v-model="DataProp.estado" label="Estado de conservacion"/>
        <mdb-input v-model="DataProp.figura" label="Forma del terreno"/>
        <mdb-input type="text" v-model="DataProp.precio" label="Precio"/>
        <mdb-input type="number" v-model="DataProp.mantenimiento" label="Mantenimiento"/>

        <mdb-input type="number" v-model="DataProp.mConstruccion" label="Metros de construcción"/>
        <select v-model="DataProp.type" v-on:click="SelectPropsType(DataProp.type)" class="browser-default custom-select">
          <option selected   value="">Select propiedad</option>
          <option value="Casa">Casa</option>
          <option value="Departamento">Departamento</option>
          <option value="Bodega">Bodega</option>
          <option value="Terreno">Terreno</option>
          <option value="Oficina">Oficina</option>
          <option value="LocalComercial">Local Comercial</option>
        </select>
        <mdb-container v-show = 'ShowPropsOp1'>
          <mdb-row >
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.Fraccionamiento" type="checkbox" class="custom-control-input"  id="Fraccionamiento">
                <label class="custom-control-label" for="Fraccionamiento">Fraccionamiento</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3">
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.Condominio" type="checkbox" class="custom-control-input" id="Condominio">
                <label class="custom-control-label" for="Condominio">Condominio</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.Privada" type="checkbox" class="custom-control-input" id="Privada">
                <label class="custom-control-label" for="Privada">Privada</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
              <div class="custom-control custom-checkbox">
                <input   v-model="DataProp.Drenaje" type="checkbox" class="custom-control-input" id="Drenaje">
                <label class="custom-control-label" for="Drenaje">Drenaje</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
              <div class="custom-control custom-checkbox">
                <input   v-model="DataProp.FosaSep" type="checkbox" class="custom-control-input" id="FosaSeptica">
                <label class="custom-control-label" for="FosaSeptica">Fosa Septica</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
              <div class="custom-control custom-checkbox">
                <input v-model="DataProp.CasaSol" type="checkbox" class="custom-control-input" id="CasaSola">
                <label class="custom-control-label" for="CasaSola">Casa Sola</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3">
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.Mascotas" type="checkbox" class="custom-control-input" id="Mascotas">
                <label class="custom-control-label" for="Mascotas">Mascotas</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3">
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.Amueblado" type="checkbox" class="custom-control-input" id="Amueblado">
                <label class="custom-control-label" for="Amueblado">Amueblado</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3">
              <div class="custom-control custom-checkbox">
                <input v-model="DataProp.AreaJue" type="checkbox" class="custom-control-input" id="Areadejuegos">
                <label class="custom-control-label" for="Areadejuegos">Area de juegos</label>
              </div>
            </mdb-col>
          </mdb-row >
          <mdb-row >
            <mdb-col col ="6" xl="2" sm="6" md="3" lg="3" >
              <mdb-input  v-model="DataProp.Habitacion" type="number"  label="Habitaciones"/>
            </mdb-col>
            <mdb-col col ="6" xl="2" sm="6" md="3" lg="3" >
              <mdb-input v-model="DataProp.Jardin" type="number" label="Jardines"/>
            </mdb-col>
            <mdb-col col ="6" xl="2" sm="6" md="3" lg="3" >
              <mdb-input v-model="DataProp.Cisterna" type="number"label="Cisternas"/>
            </mdb-col>
            <mdb-col col ="6"xl="2" sm="6" md="3" lg="3" >
              <mdb-input v-model="DataProp.Baño" type="number" label="Baños"/>
            </mdb-col>
            <mdb-col col ="6" xl="2" sm="6" md="3" lg="3" >
              <mdb-input type="number" v-model="DataProp.MedioBaño"  label="1/2 Baños"/>
            </mdb-col>
            <mdb-col col ="6" xl="2" sm="6" md="4" lg="3" >
              <mdb-input v-model="DataProp.AireAco" type="number" label="Aire Acondicionado"/>
            </mdb-col>
            <mdb-col col ="6" xl="2" sm="6" md="4" lg="3" >
              <mdb-input v-model="DataProp.CalentadorAgu" type="number" label="Calentador Agua"/>
            </mdb-col>
            <mdb-col col ="6"xl="2" sm="6" md="4" lg="3" >
              <mdb-input v-model="DataProp.Calefaccion" type="number" label="Calefacción"/>
            </mdb-col>
            <mdb-col col ="6"xl="2" sm="6" md="4" lg="3" >
              <mdb-input v-model="DataProp.SeguridadPriv" type="number" label="Seguridad Privada"/>
            </mdb-col>
            <mdb-col col ="6"xl="2" sm="6" md="4" lg="3" >
              <mdb-input v-model="DataProp.Garaje" type="number" label="Garaje"/>
            </mdb-col>
            <mdb-col col ="6"xl="2" sm="6" md="4" lg="3" >
              <mdb-input v-model="DataProp.LugarEst" type="number" label="Lugar de estacionamiento"/>
            </mdb-col>
            <mdb-col col ="6"xl="2" sm="6" md="4" lg="3" >
              <mdb-input v-model="DataProp.Alberca" type="number" label="Alberca"/>
            </mdb-col>
            <mdb-col col ="6"xl="2" sm="6" md="4" lg="3" >
              <mdb-input v-model="DataProp.Jacuzzi" type="number" label="Jacuzzi"/>
            </mdb-col>
            <mdb-col col ="6"xl="2" sm="6" md="4" lg="3" >
              <mdb-input type="number" v-model="DataProp.AlbercaCom" label="Alberca comun"/>
            </mdb-col>
            <mdb-col col ="6"xl="2" sm="6" md="4" lg="3" >
              <mdb-input v-model="DataProp.NivelesCon" type="number" label="Niveles Construidos"/>
            </mdb-col>
            <mdb-col col ="6"xl="2" sm="6" md="4" lg="3" >
              <mdb-input v-model="DataProp.CocinaInt" type="number" label="Cocina integral"/>
            </mdb-col>
            <mdb-col col ="6"xl="2" sm="6" md="4" lg="3" >
              <mdb-input v-model="DataProp.Antiguedad" type="text" label="Antiguedad"/>
            </mdb-col>
          </mdb-row >
        </mdb-container>

        <mdb-container v-show = 'ShowPropsOp2'>
          <mdb-row >
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.Techada" type="checkbox" class="custom-control-input"  id="Techada">
                <label class="custom-control-label" for="Techada">Techada</label>
              </div>
            </mdb-col>
          </mdb-row >
          <mdb-row >
            <mdb-col col ="6" xl="2" sm="6" md="4" lg="3" >
              <mdb-input  v-model="DataProp.Terreno" type="number"  label="Mts. Terreno"/>
            </mdb-col>
            <mdb-col col ="6" xl="2" sm="6" md="4" lg="3" >
              <mdb-input  v-model="DataProp.Construccion" type="number"  label="Mts. Construccion"/>
            </mdb-col>
            <mdb-col col ="6" xl="2" sm="6" md="3" lg="3" >
              <mdb-input  v-model="DataProp.Baño" type="number"  label="Baños"/>
            </mdb-col>
            <mdb-col col ="6" xl="2" sm="6" md="3" lg="3" >
              <mdb-input  v-model="DataProp.Estacionamiento" type="number"  label="Estacionamiento"/>
            </mdb-col>
          </mdb-row >
        </mdb-container>

        <mdb-container v-show = 'ShowPropsOp3'>

          <mdb-row >
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.Terreno" type="checkbox" class="custom-control-input"  id="Terreno">
                <label class="custom-control-label" for="Terreno">Terreno</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.Bardeado" type="checkbox" class="custom-control-input"  id="Bardeado">
                <label class="custom-control-label" for="Bardeado">Bardeado</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.Rustico" type="checkbox" class="custom-control-input"  id="Rustico">
                <label class="custom-control-label" for="Rustico">Rustico</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.Fraccionamiento" type="checkbox" class="custom-control-input"  id="Fraccionamiento">
                <label class="custom-control-label" for="Fraccionamiento">Fraccionamiento</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3">
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.TerrenoSolo" type="checkbox" class="custom-control-input" id="TerrenoSolo">
                <label class="custom-control-label" for="TerrenoSolo">Solo</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.Privada" type="checkbox" class="custom-control-input" id="Privada">
                <label class="custom-control-label" for="Privada">Privada</label>
              </div>
            </mdb-col>
        </mdb-row >
        </mdb-container>


        <mdb-container v-show = 'ShowPropsOp4'>
          <mdb-row >
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.PlazaComercial" type="checkbox" class="custom-control-input" id="PlazaComercial">
                <label class="custom-control-label" for="PlazaComercial">PlazaComercial</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.SobreCalle" type="checkbox" class="custom-control-input" id="SobreCalle">
                <label class="custom-control-label" for="SobreCalle">Sobre Calle</label>
              </div>
            </mdb-col>
            <mdb-col style="padding-top:22px;" col ="12" xl="3" sm="4" md="4" lg="3" >
              <div class="custom-control custom-checkbox">
                <input  v-model="DataProp.Corporativo" type="checkbox" class="custom-control-input" id="Corporativo">
                <label class="custom-control-label" for="Corporativo">Corporativo</label>
              </div>
            </mdb-col>
          </mdb-row >
          <mdb-row >
            <mdb-col col ="6"xl="2" sm="6" md="4" lg="3" >
              <mdb-input v-model="DataProp.LugarEst" type="number" label="Lugar de estacionamiento"/>
            </mdb-col>
            <mdb-col col ="6"xl="2" sm="6" md="3" lg="3" >
              <mdb-input v-model="DataProp.Baño" type="number" label="Baños"/>
            </mdb-col>
          </mdb-row >
        </mdb-container>
        <mdb-input v-model="DataProp.observacion"  type="textarea"  :rows="3" label="Observaciones"/>

      </div>
      <mdb-modal-footer>
        <mdb-btn color="secondary" @click="CloseAllTargets" >Close</mdb-btn>
        <mdb-btn color="primary" v-show="ShowAddProp" @click="CheckCp" :disabled="DataProp.id === null || DataPropSrcTem.length === 0" >Subir propiedad</mdb-btn>
        <mdb-btn color="primary" v-show="ShowEditProp" @click="UpdatePtopFireBase" :disabled="DataProp.id === null || DataPropSrcTem.length === 0" >Actualizar propiedad</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>


    <mdb-modal  centered size="lg"  v-show="modalCp">
      <mdb-modal-header>
        <mdb-modal-title>Agregar Codigo Postal</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body style="text-align: center;" >
        <mdb-row >
          <mdb-col col ="6">
            Codigo Postal : {{DataProp.cp}}
          </mdb-col>
          <mdb-col col ="6">
            <mdb-btn  @click="GoToFindCp" >Buscar</mdb-btn>
          </mdb-col>
          <mdb-col col ="12">
            <mdb-input type="number" v-model="Latitud" label="Latitud"/>
          </mdb-col>
          <mdb-col col ="12">
              <mdb-input type="number" v-model="Longitud" label="Longitud"/>
          </mdb-col>
        </mdb-row >
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" @click="modalCp = !modalCp" >Close</mdb-btn>
        <mdb-btn color="primary" v-show="ShowAddProp" @click="AddCp" :disabled="DataProp.id === null || DataPropSrcTem.length === 0" >Agregar CP</mdb-btn>
        <!-- <mdb-btn color="primary" v-show="ShowEditProp" @click="UpdatePtopFireBase" :disabled="DataProp.id === null || DataPropSrcTem.length === 0" >Actualizar propiedad</mdb-btn> -->
      </mdb-modal-footer>
    </mdb-modal>


    <mdb-modal side position="center" fullHeight direction="center" :show="ActiveLoad">
        <mdb-modal-header>
            <mdb-modal-title>SUBIENDO DATOS.....</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>Esta mensaje se cerrara una ves que los datos se hayan cargado </mdb-modal-body>
    </mdb-modal>



    <mdb-modal  centered size="lg"  v-show="modalLocalidad">
      <mdb-modal-header>
        <mdb-modal-title>Agregar Ciudad</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body style="text-align: center;" >
        <mdb-row >
          <mdb-col col ="12">
            Localidad : {{DataProp.ciudad.toUpperCase()}}
            <!-- <mdb-input type="number" v-model="DataProp.ciudad" label="Localidad"/> -->
          </mdb-col>
        </mdb-row >
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" @click="modalLocalidad = !modalLocalidad" >Close</mdb-btn>
        <mdb-btn color="primary" v-show="ShowAddProp" @click="AddLocalidad" :disabled="DataProp.id === null || DataPropSrcTem.length === 0" >Agregar</mdb-btn>
        <!-- <mdb-btn color="primary" v-show="ShowEditProp" @click="UpdatePtopFireBase" :disabled="DataProp.id === null || DataPropSrcTem.length === 0" >Actualizar propiedad</mdb-btn> -->
      </mdb-modal-footer>
    </mdb-modal>

  </div>
</template>


<script>
import router from '@/router'
import { mapActions,mapState } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import { firebase, storage, db } from "@/firebase";
import {mdbListGroup, mdbListGroupItem , mdbContainer, mdbInput,
    mdbBtn, mdbRow, mdbCol,mdbView, mdbMask,
   mdbModal,mdbModalHeader,mdbModalTitle, mdbModalBody, mdbModalFooter,mdbCarousel} from "mdbvue";

export default {
  name: 'DataProps',
  components: {
  },
  data() {
    return {
      ActiveLoad:false,
      TimeNormal:null,
      DataPropSrcTem_Delete:[],
      UpdateOnce:false,
      modalLocalidad:false,
      modalCp:false,
      modalCarrucel: false,
      fileCarrucel: null,
      MulfileCarrucel:[],
      urlTempCarrucel:'',
      MulurlTempCarrucel:[],
      ShowPropsOp1:false,
      ShowPropsOp2:false,
      ShowPropsOp3:false,
      ShowPropsOp4:false,
      DataPropSrcTem:[],
      Latitud:null,
      Longitud:null,
      DataProp:{
            latitud:0,
            longitud:0,
            info_visitas:0,
            info_likes:0,
            control_active:true,
            id_general:null,
            venta:false,
            renta:false,
            calle:"",
            numeroInt:0,
            numeroExt:0,
            colonia:"",
            cp:"",
            ciudad:"",
            estado:"",
            descripcion:"",
            observacion:"",
            mantenimiento:0,
            id:null,
            src:[],
            name:"",
            escritura_publica:"",
            fecha:new Date().getTime(),
            asesor:"",
            estado:"",
            figura:"",
            precio:0,
            mConstruccion:0,
            direccion:"",
            type:"",
            Habitacion:0,
            Jardin:0,
            Cisterna:0,
            Baño:0,
            MedioBaño:0,
            AireAco:0,
            CalentadorAgu:0,
            Calefaccion:0,
            SeguridadPriv:0,
            Garaje:0,
            LugarEst:0,
            Fraccionamiento:false,
            Condominio:false,
            Privada:false,
            Drenaje:false,
            FosaSep:false,
            CasaSol:false,
            Alberca:0,
            Jacuzzi:0,
            AlbercaCom:0,
            NivelesCon:0,
            CocinaInt:0,
            Mascotas:false,
            Amueblado:false,
            AreaJue:false,
            Antiguedad:"",
            Terreno:0,
            Construccion:0,
            Techada:false,
            Estacionamiento:0,
            Bardeado:false,
            Rustico:false,
            TerrenoSolo:false,
            PlazaComercial:false,
            SobreCalle:false,
            Coorporativo:false,

          },
    };
  },
  components:{
    mdbInput,
    mdbContainer,
    Datepicker,
    mdbCarousel,
    mdbListGroup,
    mdbListGroupItem,
    mdbBtn,
    mdbRow,
    mdbCol,
    mdbView,
    mdbMask,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
  },
  methods: {
    AddLocalidad(){
      self = this
      console.log(self.DataProp.localidad)
      db.collection("Localidades").doc(self.DataProp.ciudad.toUpperCase()).set({
          localidad:self.DataProp.ciudad.toUpperCase()
      })
      .then(function() {
          console.log("Document successfully written!");
          self.modalLocalidad = false
          alert("Nueva localidad Guardada")
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
    },
    AddCp(){
      self = this
      db.collection("CodigoPostal").doc(self.DataProp.cp).set({
          lon: parseFloat(self.Longitud),
          lat: parseFloat(self.Latitud),
          cp: parseFloat(self.DataProp.cp),
      })
      .then(function() {
          console.log("Document successfully written!");
          alert("Nuevo Cp Guardado")
          self.modalCp = false
          self.Longitud=null
          self.Latitud=null
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
    },
    GoToFindCp(){
      window.open("http://codigo-postal.es.mapawi.com/")
    },
    ConvDateToTimeStamp(){
      console.log("Convertiendo date to timestamp")
      console.log(this.TimeNormal)
      this.DataProp.fecha = Date.parse(this.TimeNormal)
      console.log(this.DataProp.fecha)
    },
    UpdatePtopFireBase(){
      console.log("Estoy actualizando datos variables")
      var self =  this
      console.log("Update........from firebase")
      console.log(this.DataPropSrcTem_Delete.length)
      console.log(this.DataPropSrcTem.length)
      for (var Iimg =0; Iimg< this.DataPropSrcTem_Delete.length ; Iimg++){
        var desertRef = storage.ref().child("ImgPropiedades").child(this.DataPropSrcTem_Delete[Iimg])
        desertRef.delete().then(function() {
            self.DataPropSrcTem_Delete=[]
            console.log("Imagen eliminada actualizadas")
          }).catch(function(error) {
            console.log("AN error to try delete")
          });
      }

      self.UpPropFireBase()
    },
    CloseAllTargets(){
      this.UpdateOnce = false
      this.ChangeValueBoolean({var:'ShowAddProp',val:false})
      this.ChangeValueBoolean({var:'ShowEditProp',val:false})
      this.CleaningGeneralData()
    },
    ...mapActions(['DeletePropFirebase','getProps','tabDataProps','ChangeValueBoolean']),
    SelectPropsType(S1){
        if(S1 =="Casa"){
          this.ShowPropsOp1=true
          this.ShowPropsOp2=false
          this.ShowPropsOp3=false
          this.ShowPropsOp4=false
        }else if(S1 =="Departamento"){
          this.ShowPropsOp1=true
          this.ShowPropsOp2=false
          this.ShowPropsOp3=false
          this.ShowPropsOp4=false
        }else if(S1 =="Bodega"){
          this.ShowPropsOp1= false
          this.ShowPropsOp2=true
          this.ShowPropsOp3=false
          this.ShowPropsOp4=false
        }else if(S1 =="Terreno"){
          this.ShowPropsOp1=false
          this.ShowPropsOp2=false
          this.ShowPropsOp3=true
          this.ShowPropsOp4=false
        }else if(S1 =="Oficina"){
          this.ShowPropsOp1=false
          this.ShowPropsOp2=false
          this.ShowPropsOp3=false
          this.ShowPropsOp4=true
        }else if(S1 =="Local Comercial"){
          this.ShowPropsOp1=false
          this.ShowPropsOp2=false
          this.ShowPropsOp3=false
          this.ShowPropsOp4=true
        }

      },
      AddPropsSrc(){
        var mul_img_name =[]
        var img_name =""
        if (this.ShowAddProp == true && this.ShowEditProp == false){
          // img_name = this.DataProp.id + "_" + this.DataPropSrcTem.length.toString() + "." + "jpg"
          for (let step = 0; step < this.MulurlTempCarrucel.length; step++) {
              let count = this.DataPropSrcTem.length
              count = this.DataPropSrcTem.length + step
              mul_img_name.push(this.DataProp.id + "_" + count.toString() + "." + "jpg")
          }

          for (let step = 0; step < this.MulurlTempCarrucel.length; step++) {
            var DataTem = {
              img: true,
              src:this.MulurlTempCarrucel[step],
              name: mul_img_name[step],
              file: this.MulfileCarrucel[step],
              from:'local'
            }
            if (this.MulurlTempCarrucel.length > 0){
              this.DataPropSrcTem.push(DataTem)
            }

          }

          // if (this.urlTempCarrucel != ""){
          //   this.DataPropSrcTem.push(DataTem)
          // }

        }else if(this.ShowAddProp == false && this.ShowEditProp == true){
          var act_index= false
          var value_index=null
          for(var i=0; i< this.DataPropSrcTem.length + 1; i++){
            for(var j=0; j< this.DataPropSrcTem.length; j++){
              if(this.DataPropSrcTem[j].name.split(".")[0].split("_").reverse()[0] == i.toString()){
                act_index= false
                break
              }else{
                act_index= true
                value_index= i
              }
            }
            if(act_index == true){

              img_name = this.DataProp.id + "_" + value_index.toString() + "." + "jpg"
              break
            }
          }
          if (img_name == ""){

            img_name = this.DataProp.id + "_" + this.DataPropSrcTem.length.toString() + "." + "jpg"
          }

          var DataTem = {
            img: true,
            src:this.urlTempCarrucel,
            name: img_name,
            file: this.fileCarrucel,
            from:'local'
          }
          if (this.urlTempCarrucel != ""){
            this.DataPropSrcTem.push(DataTem)
          }
        }



      },

      buscarImagen(event){
        this.MulfileCarrucel = []
        this.MulurlTempCarrucel=[]
        for (let step = 0; step < event.target.files.length; step++) {
            this.MulfileCarrucel.push(event.target.files[step])
            const reader = new FileReader();
            reader.readAsDataURL(this.MulfileCarrucel[step]);
            reader.onload=(e)=> {
              this.MulurlTempCarrucel.push(e.target.result);
            }

          }
      },

      DeletePropsImg(i){
        console.log("dennnnis")
        console.log(this.ShowAddProp)
        console.log(this.ShowEditProp)
        if (this.ShowAddProp == true && this.ShowEditProp == false){
          console.log(this.DataPropSrcTem.length)
          for(let step=0; step < this.DataPropSrcTem.length; step++){
            this.DataPropSrcTem[step].name = this.DataPropSrcTem[step].name.split("_")[0] + "_" + step + ".jpg"
          }
          this.removeItemFromArr(this.DataPropSrcTem,i)
        }else if(this.ShowAddProp == false && this.ShowEditProp == true){
          if (this.DataPropSrcTem[i].from == 'remote'){
            this.DataPropSrcTem_Delete.push(this.DataPropSrcTem[i].name)
          }

          console.log(this.DataPropSrcTem)
          this.removeItemFromArr(this.DataPropSrcTem,i)
          console.log(this.DataPropSrcTem)
        }
      },

      removeItemFromArr ( arr, item ) {
          var i = item;
          if ( item !== -1 ) {
              arr.splice( item, 1 );
          }
      },
      CloseCarrucel(){
        this.modalCarrucel = false
        this.CleaningGeneralData()
      },
      CheckCp(){
        console.log("ejecutando funcion Upload Propedad firebae")
        var self = this

        self.Longitud=null
        self.Latitud=null
        var Cp = "MyCp"
        if(self.DataProp.cp != ""){
          Cp = self.DataProp.cp
        }


        var docRef = db.collection("CodigoPostal").doc(Cp);
        docRef.get().then(function(doc) {
            if (doc.exists) {
                console.log("Document data:", doc.data().lat);
                self.DataProp.latitud = doc.data().lat
                self.DataProp.longitud = doc.data().lon



                var ciudad = "MyLocalidad"
                if(self.DataProp.ciudad != ""){
                  ciudad = self.DataProp.ciudad
                }


                var docRefCopy_1 = db.collection("Localidades").doc(ciudad.toUpperCase());
                docRefCopy_1.get().then(function(doc1) {
                    if (doc1.exists) {
                      self.DataProp.ciudad =self.DataProp.ciudad.toUpperCase()
                      self.UpPropFireBase()
                    }else{
                      self.modalLocalidad = true
                    }
                  }).catch(function(error) {
                      console.log("Error getting document:", error);
                  });
            } else {
                self.modalCp = true
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
      },
      async UpPropFireBase(){
        console.log("ejecutando funcion Upload Propedad firebae")
        var self = this
          try{
            self.ActiveLoad = true
            console.log(this.DataProp.fecha)
            self.modalCarrucel = false
            var NewSrc = []
            for(let step=0; step < this.DataPropSrcTem.length; step++){
              if (this.DataPropSrcTem[step].from =="local"){
                console.log("estoy en local")
                var refImagen = storage.ref().child('ImgPropiedades').child(this.DataPropSrcTem[step].name)
                var res = await refImagen.put(this.DataPropSrcTem[step].file)
                console.log(res)
                var urlDownload =  await refImagen.getDownloadURL()
                var src_data={
                  img: true,
                  src: urlDownload,
                  name: this.DataPropSrcTem[step].name,
                  from: 'remote'
                }
                NewSrc.push(src_data)
                //
                console.log(urlDownload)
              }else{
                var src_data={
                  img: true,
                  src: this.DataPropSrcTem[step].src,
                  name: this.DataPropSrcTem[step].name,
                  from: 'remote'
                }
                NewSrc.push(src_data)
              }
            };



            this.DataProp.src= NewSrc
            if(this.ShowAddProp == true && this.ShowEditProp == false) {
              db.collection("Propiedades").add({
                    DataProp:self.DataProp,
                  })
                  .then(function(docRef) {
                      console.log("Document written with ID: ", docRef.id);
                      self.DataProp.id_general = docRef.id
                      var propRef = db.collection("Propiedades").doc(docRef.id);
                          // Set the "capital" field of the city 'DC'
                          return propRef.update({
                              DataProp:self.DataProp
                          })
                          .then(function() {
                              self.modalCarrucel = false
                              self.CleaningGeneralData()
                              console.log("Document successfully updated!");
                              self.ChangeValueBoolean({var:'ActSearch',val: false})
                              self.ChangeValueBoolean({var:'Ejemplo',val:self.dataProps})
                              self.CloseAllTargets()
                              self.ActiveLoad=false
                          })
                          .catch(function(error) {
                              self.CleaningGeneralData()
                              // The document probably doesn't exist.
                              console.error("Error updating document: ", error);

                          });

                  })
                  .catch(function(error) {
                      self.CleaningGeneralData()
                      console.error("Error adding document: ", error);
                      alert("Error Upload Image")
                  });
              }else if(this.ShowAddProp == false && this.ShowEditProp == true){
                var propRef = db.collection("Propiedades").doc(self.IndexEditProp);
                    // Set the "capital" field of the city 'DC'
                    return propRef.update({
                        DataProp:self.DataProp
                    })
                    .then(function() {
                        self.modalCarrucel = false
                        self.CleaningGeneralData()
                        console.log("Document successfully updated!");
                        self.ChangeValueBoolean({var:'ActSearch',val: false})
                        self.ChangeValueBoolean({var:'Ejemplo',val:self.dataProps})
                        self.CloseAllTargets()
                        self.ActiveLoad=false
                    })
                    .catch(function(error) {
                        self.CleaningGeneralData()
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);

                    });

              }



          }catch(error){
              self.CleaningGeneralData()
            console.log(error)
          }

      },
      CleaningGeneralData(){
        this.TimeNormal=null,
        this.DataPropSrcTem_Delete=[],
        this.UpdateOnce=false
        this.modalCarrucel= false
        this.MulfileCarrucel= []
        this.fileCarrucel= null
        this.MulurlTempCarrucel = []
        this.urlTempCarrucel = ''
        this.ShowPropsOp1=false
        this.ShowPropsOp2=false
        this.ShowPropsOp3=false
        this.ShowPropsOp4=false
        this.DataPropSrcTem=[]
        this.DataProp={
              id_general:null,
              venta:false,
              renta:false,
              calle:"",
              numeroInt:0,
              numeroExt:0,
              colonia:"",
              cp:"",
              ciudad:"",
              estado:"",
              descripcion:"",
              observacion:"",
              mantenimiento:0,
              id:null,
              src:[],
              name:"",
              escritura_publica:"",
              fecha:"",
              asesor:"",
              estado:"",
              figura:"",
              precio:0,
              mConstruccion:0,
              direccion:"",
              type:"",
              Habitacion:0,
              Jardin:0,
              Cisterna:0,
              Baño:0,
              MedioBaño:0,
              AireAco:0,
              CalentadorAgu:0,
              Calefaccion:0,
              SeguridadPriv:0,
              Garaje:0,
              LugarEst:0,
              Fraccionamiento:false,
              Condominio:false,
              Privada:false,
              Drenaje:false,
              FosaSep:false,
              CasaSol:false,
              Alberca:0,
              Jacuzzi:0,
              AlbercaCom:0,
              NivelesCon:0,
              CocinaInt:0,
              Mascotas:false,
              Amueblado:false,
              AreaJue:false,
              Antiguedad:"",
              Terreno:0,
              Construccion:0,
              Techada:false,
              Estacionamiento:0,
              Bardeado:false,
              Rustico:false,
              TerrenoSolo:false,
              PlazaComercial:false,
              SobreCalle:false,
              Coorporativo:false,
            };
      }





  },
  computed:{
     ...mapState(['ShowAddProp','ShowEditProp','dataProps','IndexEditProp','ActSearch','Ejemplo']),
  },
  mounted(){

  },
  // Inicio Ciclo de vida
  // beforeCreate () {
  //   console.log('1 - beforeCreate')
  // },
  // created () {
  //   console.log('2 - created')
  // },
  // beforeMount () {
  //   console.log('3 - beforeMount')
  // },
  // mounted () {
  //   console.log('4 - mounted')
  // },
  // beforeUpdate () {
  //   console.log('5 - beforeUpdate')
  // },
  updated () {
    console.log("6-Update from DataProps.vue")
    console.log(this.ShowEditProp)
    console.log(this.UpdateOnce)

    if (this.ShowEditProp == true && this.UpdateOnce == false){
        var self =this
        console.log("hello mon")
        console.log(self.IndexEditProp)

        this.UpdateOnce=true
        this.DataPropSrcTem=[]

        var ArrayTest=[]
        var ArrayFilter = this.dataProps.filter(function(ArrayFilter) {
          return ArrayFilter.id_general == self.IndexEditProp});
          ArrayTest =  ArrayFilter.slice()

        console.log(this.dataProps)
        console.log(this.dataProps[0].id_general)
        for (var i =0; i< ArrayTest.length; i++){
          self.DataProp =Object.assign({},ArrayTest[i])

          var fecha = ArrayTest[i].fecha;
          console.log(fecha)
          this.TimeNormal = new Date(fecha)
          for(var j=0; j< this.DataProp.src.length;j++){
            this.DataPropSrcTem.push(this.DataProp.src[j])
          }

        }

    }
  },
  // beforeDestroy () {
  //   console.log('7 - beforeDestroy')
  // },
  // destroyed () {
  //   console.log('8 - destroyed')
  // }
  // Fin Ciclo de vida

}
</script>


<style>
</style>
