<template>
  <!-- ******************* Start  Recientes ************************** -->
    <div id="searchproperties">
      <!-- <p class="text-left Texto3"> Recientes</p> -->
      <mdb-container>
        <!-- <mdb-card-title class="text-left Texto3">Recientes</mdb-card-title> -->
          <mdb-container>
            <mdb-row>
              <mdb-col  sm="12" md="6" lg="4" xl="4" v-for="(item, index) in dataProps_frontend_search">
                <mdb-container  v-if="item.DataProp.control_active == true">
            		<mdb-row>
            			<mdb-card-group>
            				<mdb-card style="margin-top:50px">
            					<mdb-view hover>
            						<a href="#!">
                          <img @click="GoToPropertie(item.DataProp.id_general)" class="img1" :src="item.DataProp.src[0].src" height="200"	alt="Card image cap">
            						</a>
            					</mdb-view>
            					<mdb-card-body>
                        <mdb-card-title class="Texto4"  style=" font-weight: bold; color:#689f38  ;">{{item.DataProp.name}} </mdb-card-title>
                        <p><h1 style="font-weight: bold; color:#000000"  class="text-left">$ {{item.DataProp.precio}}</h1></strong> </p>
                        <mdb-container>
                          <mdb-row>
                            <mdb-col col="5" sm="5" md="5" lg="5" xl="6" >
                                <span id="basic-addon">
                                  <mdb-icon icon="bath" />
                                  <p>{{ item.DataProp.Baño }} Baños </p>
                                </span>
                            </mdb-col>
                            <mdb-col col="7" sm="7" md="7" lg="7" xl="6">
                                <span id="basic-addon">
                                  <mdb-icon icon="bed" />
                                  <p>{{ item.DataProp.Habitacion }} Recamaras</p>
                                </span>
                            </mdb-col>
                        </mdb-row>
                        <mdb-row>
                          <mdb-col col="5" sm="5" md="5" lg="5" xl="6" >
                              <span id="basic-addon">
                                <mdb-icon icon="car" />
                                <p>{{ item.DataProp.Garaje }} Garage </p>
                              </span>
                          </mdb-col>
                          <mdb-col col="7" sm="7" md="7" lg="7" xl="6">
                              <span id="basic-addon">
                                <img src="@/assets/terreno.png" width="22" height="22">
                                <p>{{ item.DataProp.Terreno }} m2</p>
                              </span>

                          </mdb-col>
                      </mdb-row>
                      </mdb-container>
                  			<mdb-card-text></mdb-card-text>
                  		 <mdb-btn @click="GoToPropertie(item.DataProp.id_general)" color="green">Mas Información</mdb-btn>
            					</mdb-card-body>
            				</mdb-card>
            			</mdb-card-group>
            		</mdb-row>
            	</mdb-container>

              </mdb-col sm="4">
              <br>
            </mdb-row>
          </mdb-container>
    				<!-- </mdb-card-body>
    			</mdb-card> -->
      <br>
      <div  v-show="dataProps_frontend_search.length">

         <!-- @click="siguiente" :disabled="ActiveBtnNext" -->
         <mdb-btn size="lg" @click="siguiente" :disabled="ActiveBtnNext"  class="float-center"  style="background-color: #00796b " > <strong> Ver mas </strong> </mdb-btn>
      </div>
      <br>
      <br>
      </mdb-container>
      <mdb-row>
        <mdb-col style="background-color: #00796b"   sm="2">
          <br>
        </mdb-col>
      </mdb-row>
      <br>
    </div>
  <!-- ******************* END Recientes ************************** -->
</template>





<script>
  import { firebase, storage, db } from "@/firebase";
  import { mapActions,mapState } from 'vuex'
  import { mdbFooter} from 'mdbvue';
  import { mdbRow, mdbCol, mdbCard, mdbCardImage, mdbCardHeader, mdbCardBody, mdbCardTitle, mdbCardText, mdbCardFooter, mdbCardUp, mdbCardAvatar, mdbCardGroup, mdbBtn, mdbView, mdbMask, mdbIcon } from 'mdbvue';
  import { mdbJumbotron} from 'mdbvue';
  import { mdbCarousel } from "mdbvue";
  import { mdbDropdown, mdbDropdownToggle, mdbDropdownMenu, mdbDropdownItem, mdbContainer, mdbNavbar, mdbNavbarBrand, mdbNavbarToggler, mdbNavbarNav, mdbNavItem } from 'mdbvue';
  import {mdbInput} from "mdbvue";
  export default {
    name: 'searchproperties',
    components: {
      mdbInput,
      mdbFooter,
      mdbCarousel,
      mdbNavbar,
      mdbNavbarBrand,
      mdbNavbarToggler,
      mdbNavbarNav,
      mdbNavItem,
      mdbContainer,
      mdbDropdown,
      mdbDropdownToggle,
      mdbDropdownMenu,
      mdbDropdownItem,
      mdbRow,
			mdbCol,
			mdbCard,
			mdbCardImage,
			mdbCardHeader,
			mdbCardBody,
			mdbCardTitle,
			mdbCardText,
			mdbCardFooter,
			mdbCardUp,
			mdbCardAvatar,
			mdbCardGroup,
			mdbBtn,
			mdbView,
			mdbMask,
			mdbIcon,
      mdbJumbotron,
    },
    data() {
      return {
        DataPropsFrontend:[],
        ActiveBtnNext:false
      };
    },
    methods: {
      GoToPropertie(id_general){
        var self = this
        console.log(id_general)
        self.$router.push({ name: 'Propiedad', params: { id: id_general } })
      },
      siguiente(){
        console.log("Hi from siguiente ")
        console.log(this.dataProps_last_search)
        var self= this
        var search = db.collection("Propiedades");
        if (this.dataSearch.loc != ""){
          search = search.where("DataProp.ciudad","==",this.dataSearch.loc)
        }
        if (this.dataSearch.type != ""){
          search =search.where("DataProp.type","==",this.dataSearch.type)
        }
        if (this.dataSearch.status != ""){
          if(this.dataSearch.status == "renta"){
            search = search.where("DataProp.renta","==",true)
          }else{
            search = search.where("DataProp.venta","==",true)
          }
        }
        if(this.dataSearch.min > 0){
          search = search.where("DataProp.precio",">",this.dataSearch.min)
        }
        if(this.dataSearch.max > 0){
          search = search.where("DataProp.precio","<",this.dataSearch.max)
        }
        if(this.dataSearch.bath > 0){
          search = search.where("DataProp.Baño","==",this.dataSearch.bath)
        }
        if(this.dataSearch.room > 0){
          search = search.where("DataProp.Habitacion","==",this.dataSearch.room)
        }
        if(this.dataSearch.car > 0){
          search = search.where("DataProp.Garaje","==",this.dataSearch.car)
        }
        if(this.dataSearch.terr > 0){
          search = search.where("DataProp.Terreno","==",this.dataSearch.terr)
        }
        search.limit(3)
        .startAfter(this.dataProps_last_search)
        .get()
        .then(function(querySnapshot) {
          var last = querySnapshot.docs[querySnapshot.docs.length -1];

          self.ChangeValueBoolean({var:'dataProps_last_search',val:last})

          var DataPropsFrontend = self.dataProps_frontend_search
          // var DataProps
          querySnapshot.forEach(function(doc) {
            DataPropsFrontend.push(doc.data())
            console.log(doc.id, " => ", doc.data());
          });
          self.ChangeValueBoolean({var:'dataProps_frontend_search',val:DataPropsFrontend})
        })
        .then(()=>{
          var search1 = db.collection("Propiedades")
          if (self.dataSearch.loc != ""){
            search1 = search.where("DataProp.ciudad","==",self.dataSearch.loc)
          }
          if (self.dataSearch.type != ""){
            search1 =search.where("DataProp.type","==",self.dataSearch.type)
          }
          if (self.dataSearch.status != ""){
            if(self.dataSearch.status == "renta"){
              search1 = search.where("DataProp.renta","==",true)
            }else{
              search1 = search.where("DataProp.venta","==",true)
            }
          }
          if(self.dataSearch.min > 0){
            search1 = search.where("DataProp.precio",">",self.dataSearch.min)
          }
          if(self.dataSearch.max > 0){
            search1 = search.where("DataProp.precio","<",self.dataSearch.max)
          }
          if(self.dataSearch.bath > 0){
            search1 = search.where("DataProp.Baño","==",self.dataSearch.bath)
          }
          if(self.dataSearch.room > 0){
            search1 = search.where("DataProp.Habitacion","==",self.dataSearch.room)
          }
          if(self.dataSearch.car > 0){
            search1 = search.where("DataProp.Garaje","==",self.dataSearch.car)
          }
          if(self.dataSearch.terr > 0){
            search1 = search.where("DataProp.Terreno","==",self.dataSearch.terr)
          }

          search.limit(3)
          .startAfter(this.dataProps_last_search)
          .get()
          .then(function(querySnapshot) {
                if(querySnapshot.empty)
                    self.ActiveBtnNext = true

              });

        })
      },
      ...mapActions(['ChangeValueBoolean']),

    },
    computed:{
      ...mapState(['dataProps_frontend_search','dataProps_last_search','dataSearch'])
    },
    // created () {
    //   console.log('2 - created frome Home.vue')
    //   this.ReadProps()
    // },
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Proza+Libre');
  h1 {
    font-family: "sans-serif";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
  }

  .Texto1{
    font-family: "Georgia";
    /* font-weight: bold;
    text-transform: uppercase; */
    font-size: 21px;
  }
  .Texto2{
    font-family: "Segoe UI";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 30px;
  }
  .Texto3{
    font-family: "Segoe UI";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 35px;
  }
  .Texto4{
    font-family: "Segoe UI";
    font-weight: bold;
    /* text-transform: uppercase; */
    font-size: 25px;
  }
  .container {
    text-align: center;
  }
  .img1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 98%;
}
.appWhatsapp{
  position:fixed;
  right: 26px;
  bottom:100px;
  width: 60px;
  z-index:100;
}
.appWhatsapp img{
  width:100%;
  height:auto;
}

</style>
